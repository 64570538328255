<template>
  <div class="pa-10" style="margin-left: 56px">
    <div v-if="loading">
      <Loader :loadingText="'Dashboard is loading and analyzing data...'" />
    </div>

    <div class="">
      <div>
        <v-row>
          <v-col sm="12" lg="6">
            <v-card class="mx-auto" color="grey darken-3">
              <div class="d-flex align-start justify-center">
                <v-list-item three-line>
                  <v-list-item-content>
                    <v-list-item-title class="text-h5 mb-1 font-weight-bold">
                      In Progress Loans
                    </v-list-item-title>
                    <v-list-item-subtitle
                      >Total number of in progress loans in Mortgage Automator
                      (MA).</v-list-item-subtitle
                    >
                    <v-list-item-subtitle
                      class="display-1 font-weight-bold light-blue--text"
                      >{{
                        inProgressLoans.toLocaleString("en-US")
                      }}</v-list-item-subtitle
                    >
                  </v-list-item-content>
                </v-list-item>
              </div>
            </v-card>
          </v-col>

          <v-col sm="12" lg="6">
            <v-card class="mx-auto" color="grey darken-3">
              <div class="d-flex align-start justify-center">
                <v-list-item three-line>
                  <v-list-item-content>
                    <v-list-item-title class="text-h5 mb-1 font-weight-bold">
                      Funded Loans
                    </v-list-item-title>
                    <v-list-item-subtitle
                      >Total number of funded loans in Mortgage Automator
                      (MA).</v-list-item-subtitle
                    >
                    <v-list-item-subtitle
                      class="display-1 font-weight-bold green--text text--accent-2"
                      >{{
                        fundedLoansCount.toLocaleString("en-US")
                      }}</v-list-item-subtitle
                    >
                  </v-list-item-content>
                </v-list-item>
              </div>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col sm="12" lg="4">
            <v-card class="mx-auto" color="grey darken-3">
              <div class="d-flex align-start justify-center">
                <v-list-item three-line>
                  <v-list-item-content>
                    <v-list-item-title class="text-h5 mb-1 font-weight-bold">
                      Total Cold Contacts
                    </v-list-item-title>
                    <v-list-item-subtitle
                      >Number of of cold contacts.</v-list-item-subtitle
                    >
                    <v-list-item-subtitle
                      class="display-1 font-weight-bold light-blue--text"
                      >{{
                        totalCold.toLocaleString("en-US")
                      }}</v-list-item-subtitle
                    >
                  </v-list-item-content>
                </v-list-item>
              </div>
            </v-card>
          </v-col>

          <v-col sm="12" lg="4">
            <v-card class="mx-auto" color="grey darken-3">
              <div class="d-flex align-start justify-center">
                <v-list-item three-line>
                  <v-list-item-content>
                    <v-list-item-title class="text-h5 mb-1 font-weight-bold">
                      Total Warm Contacts
                    </v-list-item-title>
                    <v-list-item-subtitle
                      >Number of warm contacts.</v-list-item-subtitle
                    >
                    <v-list-item-subtitle
                      class="display-1 font-weight-bold orange--text text--accent-4"
                      >{{
                        totalWarm.toLocaleString("en-US")
                      }}</v-list-item-subtitle
                    >
                  </v-list-item-content>
                </v-list-item>
              </div>
            </v-card>
          </v-col>

          <v-col sm="12" lg="4">
            <v-card class="mx-auto" color="grey darken-3">
              <div class="d-flex align-start justify-center">
                <v-list-item three-line>
                  <v-list-item-content>
                    <v-list-item-title class="text-h5 mb-1 font-weight-bold">
                      Total Hot Contacts
                    </v-list-item-title>
                    <v-list-item-subtitle
                      >Number of hot contacts.</v-list-item-subtitle
                    >
                    <v-list-item-subtitle
                      class="display-1 font-weight-bold green--text text--accent-2"
                      >{{
                        totalHot.toLocaleString("en-US")
                      }}</v-list-item-subtitle
                    >
                  </v-list-item-content>
                </v-list-item>
              </div>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col sm="12" lg="4">
            <v-card class="mx-auto" color="grey darken-3">
              <div class="d-flex align-start justify-center">
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title class="text-h5 mb-1 font-weight-bold">
                      Automation Timeline
                    </v-list-item-title>
                    <v-list-item-subtitle
                      >Recent automation activity feed.</v-list-item-subtitle
                    >
                  </v-list-item-content>
                </v-list-item>
              </div>

              <div class="pa-4" style="max-height: 700px; overflow: scroll">
                <template>
                  <v-timeline density="compact" side="end" align="start">
                    <v-timeline-item
                      v-for="logItem of this.jobEvent"
                      :key="logItem.Id"
                      small
                    >
                      <span class="text-gray-700 font-bold align-self-end"
                        ><strong>{{
                          logItem.AutoConfig.AutoClass.Name
                        }}</strong></span
                      >
                      <div class="mt-2 text-caption">
                        Start Time: {{ logItem.StartTime }}
                      </div>
                      <div class="mt-2 text-caption">
                        End Time: {{ logItem.EndTime }}
                      </div>
                    </v-timeline-item>
                  </v-timeline>
                </template>
              </div>
            </v-card>
          </v-col>

          <v-col sm="12" lg="8">
            <v-card color="grey darken-3" style="height: 100%">
              <div class="d-flex align-start justify-center">
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title class="text-h5 mb-1 font-weight-bold">
                      Automation Schedule
                    </v-list-item-title>
                    <v-list-item-subtitle
                      >Automation activity calendar.</v-list-item-subtitle
                    >
                  </v-list-item-content>
                </v-list-item>
              </div>

              <div class="pa-4">
                <Calendar viewtype="week" />
              </div>
            </v-card>
          </v-col>
        </v-row>

        <v-row>
          <v-col sm="12" lg="7">
            <v-card class="mx-auto" color="grey darken-3" style="height: 100%">
              <div class="d-flex align-start justify-center">
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title class="text-h5 mb-1 font-weight-bold">
                      Markets Map
                    </v-list-item-title>
                    <v-list-item-subtitle
                      >Top 10 Markets identified with ML
                      model.</v-list-item-subtitle
                    >
                  </v-list-item-content>
                </v-list-item>
              </div>
              <div class="pa-4">
                <div id="mapdiv"></div>
              </div>
            </v-card>
          </v-col>

          <v-col sm="12" lg="5">
            <v-card color="grey darken-3">
              <div class="d-flex align-start justify-center">
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title class="text-h5 mb-1 font-weight-bold">
                      Markets Table
                    </v-list-item-title>
                    <v-list-item-subtitle
                      >Top 10 Markets identified with ML
                      model.</v-list-item-subtitle
                    >
                  </v-list-item-content>
                </v-list-item>
              </div>

              <div class="pa-4">
                <!-- <v-sheet class="pa-4" color="grey darken-3" data-app>
                  <div class="d-flex align-center">
                    <v-text-field
                      v-model="search"
                      label="Search markets..."
                      flat
                      solo-inverted
                      hide-details
                      clearable
                      clear-icon="mdi-close-circle-outline"
                    ></v-text-field>
                  </div>
                </v-sheet> -->

                <div>
                  <v-data-table
                    :headers="zipRankDataHeaders"
                    :items="markets"
                    :items-per-page="limit"
                    item-key="_zip_code"
                    hide-default-footer
                    class="elevation-1"
                  >
                    <template
                      v-slot:item.query_response.AverageTransferAmount="{
                        item,
                      }"
                    >
                      <div v-if="item.query_response.AverageTransferAmount">
                        ${{
                          parseInt(
                            item.query_response.AverageTransferAmount
                          ).toLocaleString("en-US")
                        }}
                      </div>
                      <div v-else>-</div>
                    </template>
                    <template v-slot:item.actions="{ item }">
                      <router-link
                        :to="{
                          path: `/marketing/zipcode/${item._zip_code}`,
                          query: { totalRecords: totalRecords },
                        }"
                      >
                        <v-btn x-small outlined color="green accent-2">
                          View Details
                        </v-btn>
                      </router-link>
                    </template>
                  </v-data-table>
                </div>
              </div>
            </v-card>
          </v-col>
        </v-row>

        <v-row>
          <v-col lg="12">
            <v-card class="mx-auto" color="grey darken-3" width="100%">
              <v-tabs
                v-model="tab"
                color="green accent-2"
                slider-color="green accent-2"
              >
                <v-tab>MA</v-tab>
                <v-tab>APP</v-tab>
              </v-tabs>
              <v-tabs-items v-model="tab">
                <v-tab-item>
                  <!-- TMA Content -->
                  <v-sheet class="pa-4" color="grey darken-3" data-app>
                    <v-row class="align-center justify-center">
                      <v-col>
                        <v-text-field
                          class="pa-2 my-custom-search"
                          v-model="search"
                          label="Search Loan Applications..."
                          flat
                          solo-inverted
                          hide-details
                          clearable
                          clear-icon="mdi-close-circle-outline"
                        ></v-text-field>
                      </v-col>
                      <v-col align="end">
                        <v-btn small outlined @click="refreshData">
                          Refresh Application
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-sheet>
                  <div>
                    <v-data-table
                      :headers="losHeaders"
                      :items="loan"
                      :items-per-page="10"
                      item-key="email"
                      :search="search"
                      sort-by="creation_date"
                      :sort-desc="true"
                      class="elevation-1"
                    >
                      <template v-slot:item.creation_date="{ item }">
                        <div v-if="item.creation_date">
                          {{
                            new Date(item.creation_date * 1000).toLocaleString(
                              "en-US",
                              {
                                year: "2-digit",
                                month: "numeric",
                                day: "numeric",
                                hour: "numeric",
                                minute: "numeric",
                                hour12: true,
                              }
                            )
                          }}
                        </div>
                      </template>
                      <template v-slot:item.status="{ item }">
                        <div>{{ getStatusLabel(item.status) }}</div>
                      </template>
                      <template v-slot:item.actions="{ item }">
                        <router-link
                          :to="`/los/loans/${item.id}/${item.loan_id}`"
                        >
                          <v-btn x-small outlined color="green accent-2">
                            View Details
                          </v-btn>
                        </router-link>
                      </template>
                    </v-data-table>
                  </div>
                </v-tab-item>
                <v-tab-item>
                  <!-- TMA Content -->
                  <v-sheet class="pa-4" color="grey darken-3" data-app>
                    <v-row class="align-center justify-center">
                      <v-col>
                        <v-text-field
                          class="pa-2 my-custom-search"
                          v-model="search"
                          label="Search Loan Applications..."
                          flat
                          solo-inverted
                          hide-details
                          clearable
                          clear-icon="mdi-close-circle-outline"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-sheet>
                  <div>
                    <v-data-table
                      :headers="loanHeaders"
                      :items="los"
                      :items-per-page="10"
                      item-key="id"
                      :search="search"
                      sort-by="createdAt"
                      :sort-desc="true"
                      class="elevation-1"
                    >
                      <template v-slot:item.createdAt="{ item }">
                        <div v-if="item.createdAt">
                          {{
                            item.createdAt
                              .toDate()
                              .toLocaleString("en-US", {
                                year: "2-digit",
                                month: "numeric",
                                day: "numeric",
                                hour: "numeric",
                                minute: "numeric",
                                hour12: true,
                              })
                              .replace(/\//g, "-")
                          }}
                        </div>
                      </template>
                      <template v-slot:item.loanStatus="{ item }">
                        <div v-if="item.loanStatus">TBD</div>
                      </template>
                      <template v-slot:item.isProcessApplication="{ item }">
                        <div>
                          {{
                            item.isProcessApplication
                              ? item.isProcessApplication
                              : false
                          }}
                        </div>
                      </template>
                      <template v-slot:item.actions="{ item }">
                        <router-link :to="`/los/loans/${item.id}`">
                          <v-btn x-small outlined color="green accent-2">
                            View Details
                          </v-btn>
                        </router-link>
                      </template>
                    </v-data-table>
                  </div>
                </v-tab-item>
              </v-tabs-items>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import { firebaseDB } from "../../auth/firebase-auth";
import Loader from "../../components/Loader.vue";
import Calendar from "../../components/Calendar.vue";

export default {
  components: {
    Loader,
    Calendar,
  },
  props: ["id"],
  data() {
    return {
      loading: true,
      jobEvent: [],
      color: "success",
      snackbar: false,
      snackBarText: "",
      snackBarTimeout: 2000,
      page: 1,
      limit: 10,
      totalRecords: 0,
      zipCode: null,
      zipCodes: [],
      tab: "",
      loan: [],
      los: [],
      fundedLoansCount: 0,
      inProgressLoans: 0,
      totalHot: 0,
      totalWarm: 0,
      totalCold: 0,
      losLogObject: null,
      dialog: false,
      selectedState: "all",
      apiKey: "AIzaSyBkvot_wof6iT87tGnaYMA9ZFIFRvZOK5o",
      map: null,
      markets: [],
      zipRankDataHeaders: [
        {
          text: "Zipcode",
          value: "_zip_code",
        },
        {
          text: "City",
          value: "major_city",
        },
        {
          text: "State",
          value: "query_response.PropertyAddressState",
        },
        {
          text: "Flippers",
          value: "query_response.UniqueFlippersCount",
        },
        // {
        //   text: "No of Unique Lending Companies",
        //   value: "query_response.UniqueLenderCompaniesCount",
        // },
        {
          text: "Avg Flip $",
          value: "query_response.AverageTransferAmount",
        },
        // {
        //   text: "Population",
        //   value: "population",
        // },
        // {
        //   text: "WC Market Share",
        //   value: "WildcatMarketShare",
        // },
        // {
        //   text: "WC Market Share Rank",
        //   value: "WildcatMarketShareRank",
        // },
        // {
        //   text: "isActive",
        //   value: "is_active",
        // },
        // {
        //   text: "Crebrid Rank",
        //   value: "ziprank",
        // },
        {
          text: "Actions",
          value: "actions",
        },
      ],
      losHeaders: [
        {
          text: "Loan Title",
          align: "start",
          value: "loan_title",
        },
        {
          text: "Reference #",
          align: "start",
          value: "loan_id",
        },
        {
          text: "Address",
          align: "start",
          value: "property.address.street",
        },
        {
          text: "City",
          align: "start",
          value: "property.address.city",
        },
        {
          text: "State",
          align: "start",
          value: "property.address.prov",
        },
        {
          text: "Created At",
          align: "start",
          value: "creation_date",
        },
        {
          text: "Status",
          align: "center",
          value: "status",
        },
        {
          text: "Actions",
          align: "center",
          value: "actions",
        },
      ],
      loanHeaders: [
        {
          text: "Email",
          align: "start",
          width: "100",
          value: "email",
        },
        {
          text: "Reference #",
          align: "start",
          width: "50",
          value: "ReferenceNumber",
        },
        {
          text: "Address",
          align: "start",
          width: "100",
          value: "street",
        },
        {
          text: "City",
          align: "start",
          width: "50",
          value: "city",
        },
        {
          text: "State",
          align: "start",
          width: "50",
          value: "state",
        },
        // {
        //   text: "Zipcode",
        //   align: "start",
        //   width: "50",
        //   value: "P1005",
        // },
        {
          text: "Created At",
          align: "start",
          width: "100",
          value: "createdAt",
        },
        {
          text: "Processed",
          align: "center",
          width: "100",
          value: "isProcessApplication",
        },
        {
          text: "Actions",
          align: "center",
          width: "100",
          value: "actions",
        },
      ],
      search: "",
    };
  },
  computed: {
    // tab: {
    //   set(tab) {
    //     this.$router.replace({ query: { ...this.$route.query, tab } });
    //   },
    //   get() {
    //     return this.$route.query.tab;
    //   },
    // },
  },
  filters: {
    formatDate: function (date) {
      return moment(date).format("MMMM Do YYYY, h:mm:ss a");
    },
  },
  mounted() {
    this.getProjects();
    this.fetchLosData();
    this.fetchLoanData();
    this.fetchCrmData();
    this.fetchShapesData();
    this.fetchMarketLeadsData();
    this.getAutoJobEvent();
  },
  methods: {
    getStatusLabel(statusCode) {
      const statusMap = {
        0: "In Progress",
        1: "Funded",
        2: "Discharged",
        3: "Terminated",
      };
      return statusMap[statusCode] || "Unknown Status";
    },
    async getProjects() {
      try {
        this.loading = true;
        const key = "21b77cec7cb516c40d9d7216ae74e3c3";
        this.limit = 10;
        console.log("limit--", this.limit);
        console.log("selectedState----", this.selectedState);
        const collectionRef = firebaseDB.firestore().collection('markets_final').limit(this.limit); //Markets collection name
        let query = collectionRef.orderBy("ziprank", "desc");

        if (this.selectedState !== "all") {
          query = query.where("query_response.PropertyAddressState", "==", this.selectedState.toUpperCase());
        }
        const querySnapshot = await query.get();

        this.markets = querySnapshot.docs.map((doc) => {
          const { id } = doc;
          const data = doc.data();
          
          if (data._zip_code) {
            this.zipCodes.push(data._zip_code);
          }
          return { id, ...data };
        });
        console.log("zipRankData-------", this.markets);
        this.totalRecords =
          this.markets[this.markets.length - 1]["TotalCount"];
        this.loadMap();
      } catch (error) {
        console.log("Error in fetching ziprank data:\n" + error);
      }
    },
    async getLatLongFromZipcode(zipcode) {
      const apiKey = this.apiKey;
      const url = `https://maps.googleapis.com/maps/api/geocode/json?components=postal_code:${zipcode}&key=${apiKey}`;

      try {
        const response = await axios.get(url);
        const data = response.data;

        if (data.status === "OK" && data.results.length > 0) {
          const result = data.results[0];
          const lat = result.geometry.location.lat;
          const lng = result.geometry.location.lng;
          return { latitude: lat, longitude: lng };
        } else {
          return { latitude: "", longitude: "" };
        }
      } catch (error) {
        console.error("Error:", error.message);
        return null;
      }
    },
    async loadMap() {
      this.loading = true;
      const canvas = document.createElement("canvas");
      const gl = canvas.getContext("webgl");
      gl.getParameter(gl.RENDERER);

      const mapDiv = document.getElementById("mapdiv");

      const zipCode = this.zipCodes[1];
      console.log(zipCode);

      const zoom = 5;
      const latLong = { lat: 32.7559666, lng: -97.1911707 }
      const options = {
        center: latLong,
        mapId: "1261b76f0156780e",
        zoom,
        preserveDrawingBuffer: true,
      };
      this.map = new google.maps.Map(mapDiv, options);
      for (const zipCode of this.markets) {
        let location = {};
        console.log("zipCode obj", zipCode);
        if (zipCode.latitude !== undefined &&
            zipCode.longitude !== undefined) {
            location.latitude = zipCode.latitude;
            location.longitude = zipCode.longitude;
        }
        else{
          location = await this.getLatLongFromZipcode(
                zipCode.query_response.PropertyAddressZIP
              );
          await firebaseDB
                .firestore()
                .collection("markets_final")
                .doc(zipCode.id)
                .update({
                        "latitude": location.latitude,
                        "longitude": location.longitude
                      });
          await firebaseDB
                .firestore()
                .collection("mortgageLoans")
                .doc(zipCode.id)
                .update({
                        "property.address.latitude": location.latitude,
                        "property.address.longitude": location.longitude
                      });
        }
        if (!location || !location.latitude || !location.longitude) {
            console.error("Error fetching location data");
            continue; // Skip this marker
        }
        const { latitude, longitude } = location;
        const latLng = { lat: latitude, lng: longitude };
        const local_marker = new google.maps.Marker({
          position: latLng,
          map: this.map,
        });
        this.marker = local_marker;
        var infoWindow = new google.maps.InfoWindow({
          content: `
          <div id="content">
              <div id="bodyContent">
              </br>
              <p style='color: black;'>
                  <b>Zipcode:</b> ${zipCode._zip_code}
              </p>
              <p style='color: black;'>
                  <b>State:</b> ${zipCode.query_response.PropertyAddressState}
              </p>
              <p style='color: black;'>
                  <b>City:</b> ${zipCode.major_city}
              </p>
              <p style='color: black;'>
                  <b>Population:</b> ${zipCode.population}
              </p>
              <p style='color: black;'>
                  <b>WildcatMarketShare:</b> ${zipCode.WildcatMarketShare}
              </p>
              <p style='color: black;'>
                  <b>WildcatMarketShareRank:</b> ${
                    zipCode.WildcatMarketShareRank
                  }
              </p>
              <p style='color: black;'>
                  <b>Crebrid Rank:</b> ${zipCode.ziprank}
              </p>
              <p style='color: black;'>
                  <b>No of Unique Flippers:</b> ${
                    zipCode.query_response.UniqueFlippersCount
                  }
              </p>
              <p style='color: black;'>
                  <b>No of Unique Lending Companies:</b> ${
                    zipCode.query_response.UniqueLenderCompaniesCount
                  }
              </p>
              <p style='color: black;'>
                  <b>Avg Flip Transfer Amount:</b> $${parseInt(
                    zipCode.query_response.AverageTransferAmount
                  ).toLocaleString("en-US")}
              </p>
              </div>
          </div>  
              `,
        });

        local_marker.addListener(
          "click",
          (function (marker, infoWin) {
            return function () {
              infoWin.open(this.map, marker);
            };
          })(local_marker, infoWindow)
        );
      }

      
      this.loading = false;
    },
    async fetchLosData() {
      this.loading = true;
      const losRef = firebaseDB.firestore().collection("mortgageLoans");
      try {
        const { docs } = await losRef
          .orderBy("creation_date", "desc")
          .limit(50)
          .get();
        const inProgressQuery = await losRef.where("status", "==", 0).get(); // Filter by in-progress status
        const fundedQuery = await losRef.where("status", "==", 1).get(); // Filter by funded status
        this.loan = docs.map((doc) => {
          const { id } = doc;
          const data = doc.data();
          return { id, ...data };
        });
        this.inProgressLoans = inProgressQuery.docs.length;
        this.fundedLoansCount = fundedQuery.docs.length;
      } catch (error) {
        console.log("Error in fetching loan data:\n" + error);
      }
    },
    async fetchLoanData() {
      this.loading = true;
      const loansRef = firebaseDB.firestore().collection("appLoans");
      try {
        const { docs } = await loansRef
          .orderBy("createdAt", "desc")
          .limit(50)
          .get();
        this.los = docs.map((doc) => {
          const { id } = doc;
          const data = doc.data();
          return { id, ...data };
        });
      } catch (error) {
        console.log("Error in fetching los data:\n" + error);
      }
    },
    async refreshData() {
      this.loading = true;
      try {
        const url =
          location.hostname === "localhost"
            ? `http://localhost:5000`
            : `https://hermosa-server-dot-crebrid-os.uc.r.appspot.com`;

        const headers = {
          "Content-Type": "application/json",
        };

        await axios.post(`${url}/api/v1/pdf/mortgage-loans`, { headers });
      } catch (error) {
        console.log("Error in fetching los data:\n" + error);
      }
    },
    async fetchCrmData() {
      this.loading = true;
      const crmRef = firebaseDB.firestore().collection("contacts");
      try {
        const { docs } = await crmRef.get();

        this.crm = docs.map((doc) => {
          const { id } = doc;
          const data = doc.data();
          return { id, ...data };
        });
        this.totalHot = this.crm.length;
      } catch (error) {
        console.log("Error in fetching los data:\n" + error);
      }
    },

    async fetchShapesData() {
      this.loading = true;

      try {
        const key = "21b77cec7cb516c40d9d7216ae74e3c3";
        await axios
          .get(
            `https://crebrid-flask-dot-crebrid-os.uc.r.appspot.com/shapes?page=${this.page}&limit=${this.limit}&key=${key}`
          )
          .then((response) => {
            this.totalWarm = response.data.TotalRecords;
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
      } catch (error) {
        console.log("Error in fetching Shapes data:\n" + error);
      }
    },

    async fetchMarketLeadsData() {
      this.loading = true;

      try {
        const key = "21b77cec7cb516c40d9d7216ae74e3c3";

        let apiURL = `https://crebrid-flask-dot-crebrid-os.uc.r.appspot.com/leads?page=${this.page}&limit=${this.limit}&totalRecords=${this.totalLeads}&key=${key}`;

        await axios
          .get(apiURL)
          .then((response) => {
            this.totalCold = response.data.flips[0].TotalRecords;
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
      } catch (error) {
        console.log("Error in fetching Leads data:\n" + error);
      }
    },
    async getAutoJobEvent() {
      try {
        this.loading = true;
        const yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);

        const today = new Date();
        const startdate = yesterday.toISOString().slice(0, 10);
        const enddate = today.toISOString().slice(0, 10);
        await axios
          .get(
            process.env.VUE_APP_BASEURL +
              `/scheduler/autojob?startDate=${startdate},&endDate=${enddate}"&isCal=false`
          )
          .then((response) => {
            if (response.data) {
              this.jobEvent =
                response.data.data.autoJobs.length > 0
                  ? response.data.data.autoJobs.slice(0, 20)
                  : [];
            }
          });
      } catch (error) {
        console.log("Error in fetching events data:\n" + error);
      }
    },

    moment: function () {
      return moment();
    },
  },
};
</script>

<style lang="scss" scoped>
.os-container {
  min-height: calc(100vh - 120px);
}
#mapdiv {
  min-height: 500px;
  width: 100%;
}
</style>
