import Vue from "vue";
import firebase from "firebase";
import router from "./../routes";

var firebaseConfig = {
  apiKey: "AIzaSyBuAjZcpQ0MUInVCmTAEjFQS_MVpBRTlYg",
  authDomain: "crebrid-os.firebaseapp.com",
  projectId: "crebrid-os",
  storageBucket: "crebrid-os.appspot.com",
  messagingSenderId: "181422419952",
  appId: "1:181422419952:web:822ab7e40ea4970e1eb735",
};

var firebaseAppConfig = {
  apiKey: "AIzaSyCg9Qa8ihg7bEnFSmygE0o01FBo1KEV9bg",
  authDomain: "crebrid-app.firebaseapp.com",
  projectId: "crebrid-app",
  appId: "1:701205184517:android:f6dd9261fbf6a3a9c49c6c",
};

var firebaseWebConfig = {
  apiKey: "AIzaSyDfUFLzX_pHjHCzHgEe5z2GboJvg9mjc2k",
  authDomain: "crebrid-web.firebaseapp.com",
  projectId: "crebrid-web",
  storageBucket: "crebrid-web.appspot.com",
  messagingSenderId: "953867308705",
  appId: "1:953867308705:web:92dbba3821ef36036feae0",
};

export const firebasewebDB = firebase.initializeApp(
  firebaseWebConfig,
  "crebridweb"
);
export const firebaseappDB = firebase.initializeApp(
  firebaseAppConfig,
  "crebridapp"
);
export const firebaseDB = firebase.initializeApp(firebaseConfig);

// Initialize Storage
export const storage = firebasewebDB.storage();

/** Define a default action to perform after authentication */
const DEFAULT_REDIRECT_CALLBACK = () => {
  window.history.replaceState({}, document.title, window.location.pathname);
};

const Login_Redirect = () => router.push("/login");

let instance;

/** Returns the current instance of the SDK */
export const getInstance = () => instance;

/** Creates an instance of the Auth0 SDK. If one has already been created, it returns that instance */
export const firebaseAuth = ({
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  redirectUri = window.location.origin,
  ...options
}) => {
  if (instance) return instance;

  instance = new Vue({
    data() {
      return {
        loading: true,
        isAuthenticated: false,
        user: {},
        auth0Client: null,
        popupOpen: false,
        error: null,
      };
    },
    methods: {
      /** Authenticates the user using a popup window */
      async loginWithPopup(o) {
        this.popupOpen = true;
        try {
          const provider = new firebase.auth.GoogleAuthProvider();
          firebase
            .auth()
            .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
            .then(async () => {
              await firebase
                .auth()
                .signInWithPopup(provider)
                .then((res) => {
                  if (res.user && res.user.email.endsWith("@crebrid.com")) {
                    console.log("Login done!", res.user);
                    this.user = res.user;
                    this.isAuthenticated = true;
                    this.$cookies.set("isAuthenticated", true, Infinity);
                    this.$cookies.set(
                      "user",
                      JSON.stringify(this.user),
                      Infinity
                    );
                    onRedirectCallback();
                  } else {
                    alert("Login rejected. Email not from the allowed domain.");
                    console.error(
                      "Login rejected. Email not from the allowed domain."
                    );
                    firebase.auth().signOut(); // Optional: You can sign out the user immediately if not from the allowed domain
                  }
                });
            });
        } catch (e) {
          // eslint-disable-next-line
          console.error(e);
        } finally {
          this.popupOpen = false;
          this.loading = false;
        }
      },
      /** Logs the user out of app*/
      logout(o) {
        return firebase
          .auth()
          .signOut()
          .then((res) => {
            this.$cookies.remove("isAuthenticated");
            this.$cookies.remove("user");
            this.$cookies.remove("pathname");
            this.isAuthenticated = false;
            Login_Redirect();
          });
      },
      /** Authenticates the user using the redirect method */
      loginWithRedirect(o) {
        console.log(o);
        // const provider = new firebase.auth.GoogleAuthProvider();
        // return firebase.auth().signInWithRedirect(provider);
      },
    },
    async created() {
      try {
        // If the user is returning to the app after authentication..
        // if (
        //     window.location.search.includes("code=") &&
        //     window.location.search.includes("state=")
        // )

        if (this.$cookies.get("isAuthenticated")) {
          // handle the redirect and retrieve tokens
          //const { appState } = await this.auth0Client.handleRedirectCallback();
          this.$cookies.set("pathname", window.location.pathname);
          window.history.replaceState({}, document.title, "/#");

          // Notify subscribers that the redirect callback has happened, passing the appState
          // (useful for retrieving any pre-authentication state)
        }
      } catch (e) {
        this.error = e;
      } finally {
        // Initialize our internal authentication state
        console.log("Finally block called!");
        this.isAuthenticated = this.$cookies.get("isAuthenticated")
          ? true
          : false;
        this.user = this.$cookies.get("user") ? this.$cookies.get("user") : {};
        this.loading = false;
      }
    },
  });
  console.log(instance);
  if (instance.isAuthenticated) {
    onRedirectCallback();
  }
  return instance;
};

// Create a simple Vue plugin to expose the wrapper object throughout the application
export const FirebasePlugin = {
  install(Vue, options) {
    Vue.prototype.$auth = firebaseAuth(options);
  },
};
