<template>
  <div class="full-height pa-10" style="margin-left: 56px">
    <div v-if="loading">
      <Loader :progress="progress" />
    </div>
    <div v-if="loadingBar">
      <ProgressBar :progress="progress" />
    </div>

    <v-snackbar v-model="snackbar" :color="snackbarColor" top
      >{{ snackBarText }}
      <v-btn color="white" class="ml-2" text @click="snackbar = false">
        Close
      </v-btn></v-snackbar
    >

    <div class="mx-auto rounded-0 full-height" v-if="los != null">
      <div v-if="losObject != null">
        <v-sheet :elevation="5" dark class="pa-5 mb-10">
          <div class="d-flex align-center">
            <div class="text-subtitle-1 pr-5">Application: {{ this.id }}</div>

            <v-spacer />

            <div v-if="loanApproval && loanApproval.preapproved == true">
              <div class="text-subtitle-1 green--text text--accent-2 pr-5">
                Approved
              </div>
            </div>

            <div v-else-if="loanApproval && loanApproval.preapproved == false">
              <div class="red--text text--accent-2 pr-5">Not Approved</div>
            </div>

            <template>
              <v-btn
                class="mr-5"
                color="white"
                small
                outlined
                @click="dialog = true"
              >
                View Logs
              </v-btn>

              <v-dialog v-model="dialog" width="auto">
                <v-card width="600">
                  <v-card-title class="text-h6">
                    Loan Application Logs

                    <v-spacer />

                    <div class="c-pointer d-flex" @click="dialog = false">
                      <v-icon>mdi-close</v-icon>
                    </div>
                  </v-card-title>
                  <v-divider></v-divider>
                  <v-card-text class="mt-5">
                    <template>
                      <div class="mb-2">
                        <div class="mt-2">
                          Total Time to Process:
                          {{ executionTimeMinutes }} minutes
                          {{ executionTimeSeconds }} seconds
                        </div>
                        <div class="mt-2">
                          Total Cost to Process:
                          {{ formatCurrencyProcessing(totalProcessingCost) }}
                          (ATTOM API:
                          {{
                            formatCurrencyProcessing(attomApiCallCountValue)
                          }}, OpenAI:
                          {{ formatCurrencyProcessing(losObject.openAiCost) }})
                        </div>
                      </div>
                      <v-timeline density="compact" side="end" align="start">
                        <v-timeline-item
                          v-for="logItem of this.losLogObject"
                          :key="logItem.id"
                          :color="logItem.status == 'Success' ? 'blue' : 'red'"
                          small
                        >
                          <span class="text-gray-700 font-bold align-self-end"
                            ><strong>{{ logItem.title }}</strong> |
                            {{ logItem.time }}</span
                          >
                          <div class="mt-2 text-caption">
                            {{ logItem.description }}
                          </div>
                        </v-timeline-item>
                      </v-timeline>
                    </template>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn
                      class="btn-item custom-bg-secondary"
                      dark
                      small
                      block
                      @click="dialog = false"
                      >Close Logs</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <v-btn
                class="mr-5"
                color="white"
                small
                outlined
                @click="openDialog"
              >
                Analyze with AI
              </v-btn>

              <v-dialog v-model="aiDialog" width="auto">
                <v-card width="600">
                  <v-card-title class="text-h6">
                    Analyze with AI

                    <v-spacer />

                    <div class="c-pointer d-flex" @click="aiDialog = false">
                      <v-icon>mdi-close</v-icon>
                    </div>
                  </v-card-title>
                  <v-divider></v-divider>
                  <v-card-text>
                    <v-container>
                      <v-row
                        v-for="(item, index) in parsedContent"
                        :key="index"
                      >
                        <v-col v-if="item.type === 'title'" cols="12">
                          <h3 v-html="item.content"></h3>
                        </v-col>
                        <v-col v-else-if="item.type === 'subtitle'" cols="12">
                          <h4 v-html="item.content"></h4>
                        </v-col>
                        <v-col v-else-if="item.type === 'bullet'" cols="12">
                          <p v-html="'- ' + item.content"></p>
                        </v-col>
                        <v-col v-else-if="item.type === 'numbered'" cols="12">
                          <p v-html="item.content"></p>
                        </v-col>
                        <v-col v-else-if="item.type === 'text'" cols="12">
                          <p v-html="item.content"></p>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn
                      class="btn-item custom-bg-secondary"
                      dark
                      small
                      block
                      @click="aiDialog = false"
                    >
                      Close
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </template>

            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="white" small outlined v-bind="attrs" v-on="on">
                  Automations
                </v-btn>
              </template>
              <v-list>
                <v-list-item>
                  <v-btn
                    color="white"
                    small
                    outlined
                    @click="processApplication"
                  >
                    Process Application
                  </v-btn>
                </v-list-item>

                <v-list-item>
                  <v-btn color="white" small outlined @click="generateReport()">
                    Download Pre Approval Letter
                  </v-btn>
                </v-list-item>
                <v-list-item v-if="!this.loading && this.losObject">
                  <v-btn
                    color="white"
                    small
                    outlined
                    @click="generateLoanReport()"
                  >
                    Download Term Sheet
                  </v-btn>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </v-sheet>

        <v-row class="mb-0">
          <v-col lg="4">
            <v-card color="grey darken-3" height="100%">
              <v-card-text>
                <div class="d-flex">
                  Investor
                  <div
                    v-bind:style="[
                      loanApproval && loanApproval.preapproved
                        ? { background: '#69f0ae !important' }
                        : { background: ' #ff5252!important' },
                    ]"
                    class="round"
                  ></div>
                </div>

                <div v-if="loanApproval">
                  <div v-if="loanApproval.preapproved == true">
                    <div class="text-h5 green--text text--accent-2 pt-2 pb-4">
                      {{ loanApproval.score.toFixed(2) }}/25
                    </div>
                  </div>

                  <div
                    v-else-if="
                      loanApproval && loanApproval.preapproved == false
                    "
                  >
                    <div class="text-h5 red--text text--accent-2 pt-2 pb-4">
                      {{ loanApproval && loanApproval.score.toFixed(2) }}/25
                    </div>
                  </div>

                  <div v-else class="text-h5 text--primary pt-2 pb-4">
                    Not Available
                  </div>
                </div>

                <p class="mb-1">
                  {{ this.losObject.firstName }} {{ this.losObject.lastName }},
                  {{ this.losObject.email }}
                </p>

                <p class="orange--text">
                  CS: {{ this.losObject.credirScore }}, L:
                  {{ formatStrCurrency(this.losObject.liquidityAssets) }}
                </p>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col lg="4">
            <v-card color="grey darken-3" height="100%">
              <v-card-text>
                <div class="d-flex">
                  Keystone
                  <div
                    v-bind:style="[
                      loanApproval && loanApproval.preapproved
                        ? { background: '#69f0ae !important' }
                        : { background: ' #ff5252!important' },
                    ]"
                    class="round"
                  ></div>
                </div>
                <div
                  v-if="loanApproval && loanApproval.preapproved == true"
                  class="d-inline-flex align-center"
                >
                  <div
                    v-if="avmProperty"
                    class="text-h5 green--text text--accent-2 pt-2 pb-4 pr-4"
                  >
                    {{ formatKCurrency(this.keystoneV3lowV2) }} -
                    {{ formatKCurrency(this.keystoneV3highV2) }}
                  </div>
                  <div
                    v-if="avmProperty"
                    class="light-blue--text text--lighten-2"
                  >
                    (<span>X&#771;</span>
                    {{ formatKCurrency(this.keystoneV3medianV2) }}, Z
                    {{ formatKCurrency(losObject?.proxseyV2ARV ?? 0) }})
                  </div>
                </div>

                <div
                  v-else-if="loanApproval && loanApproval.preapproved == false"
                  class="d-inline-flex align-center"
                >
                  <div
                    v-if="avmProperty"
                    class="text-h5 red--text text--accent-2 pt-2 pb-4 pr-4"
                  >
                    {{ formatKCurrency(this.keystoneV3lowV2) }} -
                    {{ formatKCurrency(this.keystoneV3highV2) }}
                  </div>

                  <div
                    v-if="avmProperty"
                    class="light-blue--text text--lighten-2"
                  >
                    (<span>X&#771;</span>
                    {{ formatKCurrency(this.keystoneV3medianV2) }}, Z
                    {{ formatKCurrency(losObject?.proxseyV2ARV ?? 0) }})
                  </div>
                </div>

                <p class="mb-1" v-if="this.losObject && this.losObject.street">
                  {{ this.losObject.street }}, {{ this.losObject.city }},
                  {{ this.losObject.state }}, {{ this.losObject.zip }}
                </p>

                <p class="orange--text">
                  PP: {{ formatStrCurrency(this.losObject.purchasePrice) }},
                  NLE:{{
                    this.LE !== "" && this.LE !== null
                      ? formatCurrency(this.LE)
                      : "N/A"
                  }}, IARV: {{ formatCurrency(this.losObject.arvValue) }}, RB:{{
                    this.losObject.rehabBudget
                  }}
                </p>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col lg="4">
            <v-card color="grey darken-3" height="100%">
              <v-card-text>
                <div class="d-flex">
                  Viability
                  <div
                    v-bind:style="[
                      loanApproval && loanApproval.preapproved
                        ? { background: '#69f0ae !important' }
                        : { background: ' #ff5252!important' },
                    ]"
                    class="round"
                  ></div>
                </div>

                <div v-if="loanApproval && loanApproval.preapproved == true">
                  <div class="text-h5 green--text text--accent-2 pt-2 pb-4">
                    {{
                      (this.viabilityData &&
                      this.viabilityData.confidence &&
                      this.viabilityData.confidence != "N/A"
                        ? this.viabilityData.confidence
                        : 0
                      ).toFixed(2)
                    }}
                    %
                  </div>
                </div>

                <div
                  v-else-if="loanApproval && loanApproval.preapproved == false"
                >
                  <div class="text-h5 red--text text--accent-2 pt-2 pb-4">
                    {{
                      (this.viabilityData &&
                      this.viabilityData.confidence &&
                      this.viabilityData.confidence != "N/A"
                        ? this.viabilityData.confidence
                        : 0
                      ).toFixed(2)
                    }}
                    %
                  </div>
                </div>

                <p class="mb-1">
                  Exposure
                  <span
                    v-if="
                      this.viabilityData &&
                      this.viabilityData.exposure &&
                      this.viabilityData.exposure !== 'N/A'
                    "
                    >:
                    {{
                      this.viabilityData && this.viabilityData.exposure
                        ? this.viabilityData.exposure
                        : 0
                    }}% of</span
                  >
                  <span v-else>NA</span>
                  <span
                    v-if="avmProperty"
                    class="light-blue--text text--lighten-2 pl-1"
                  >
                    <span>X&#771;</span>
                    {{
                      formatCurrency(
                        this.viabilityData &&
                          this.viabilityData.keystoneV3median
                          ? this.viabilityData.keystoneV3median
                          : 0
                      )
                    }}
                  </span>
                </p>

                <p class="orange--text">
                  Investor Gross Profit:
                  {{
                    formatCurrency(
                      this.viabilityData &&
                        this.viabilityData.profitDollarAmount
                        ? this.viabilityData.profitDollarAmount
                        : 0
                    )
                  }}
                  {{ this.profitPercentage }}%
                </p>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <v-row class="mt-0 mb-6">
          <v-col lg="4">
            <v-card color="grey darken-3" height="100%">
              <v-card-text>
                <div class="d-flex">
                  Larceny Index (0-200 100 = National Average)
                </div>
                <div v-if="this.attomDataObject.neighborhoodCommunityData">
                  <div class="text-h5 green--text text--accent-2 pt-2 pb-4">
                    {{
                      this.attomDataObject.neighborhoodCommunityData?.crime
                        ?.larceny_Index
                    }}
                  </div>
                </div>
                <div v-else class="text-h5 text--primary pt-2 pb-4">
                  Not Available
                </div>

                <p
                  class="mb-1"
                  v-if="this.attomDataObject.neighborhoodCommunityData.crime"
                >
                  Aggravated Assault:
                  {{
                    this.attomDataObject.neighborhoodCommunityData.crime
                      ?.aggravated_Assault_Index
                  }}
                  Homicide:
                  {{
                    this.attomDataObject.neighborhoodCommunityData.crime
                      ?.murder_Index
                  }}
                </p>

                <p
                  class="orange--text"
                  v-if="this.attomDataObject?.neighborhoodCommunityData.crime"
                >
                  Overall Crime Index:
                  {{
                    this.attomDataObject?.neighborhoodCommunityData.crime
                      ?.crime_Index
                  }}
                </p>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col lg="4">
            <v-card color="grey darken-3" height="100%">
              <v-card-text>
                <div class="d-flex">Affordability</div>

                <div
                  v-if="this.losObject?.affordabilityPercentage"
                  class="text-h5 green--text text--accent-2 pt-2 pb-4 pr-4"
                >
                  {{ (this.losObject?.affordabilityPercentage).toFixed(2) }}%
                </div>
                <div
                  v-else
                  class="text-h5 green--text text--accent-2 pt-2 pb-4 pr-4"
                >
                  Not Available
                </div>

                <p
                  class="mb-1"
                  v-if="
                    this.attomDataObject?.neighborhoodCommunityData.demographics
                  "
                >
                  Income Under $15,000:
                  {{
                    this.attomDataObject?.neighborhoodCommunityData.demographics
                      ?.households_Income_Less_Than_15000_Pct
                  }}%
                </p>

                <p
                  class="orange--text"
                  v-if="this.attomDataObject.neighborhoodCommunityData"
                >
                  Population In Poverty:
                  {{
                    this.attomDataObject.neighborhoodCommunityData?.demographics
                      ?.population_In_Poverty_Pct
                  }}%
                </p>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col lg="4">
            <v-card color="grey darken-3" height="100%">
              <v-card-text>
                <div class="d-flex">Neighborhood HE</div>
                <div
                  v-if="this.saleObj"
                  class="green--text text--accent-2 pt-2 pb-4 pr-4"
                >
                  <span class="text-h5">{{
                    formatKCurrency(neighborhoodHEValue)
                  }}</span>

                  <span
                    v-if="this.saleObj"
                    class="light-blue--text text--lighten-2 pl-1"
                    style="fontsize: 10px"
                  >
                    ({{
                      this.saleObj && this.saleObj[0].AVMPSF !== undefined
                        ? this.formatKCurrency(this.saleObj[0].AVMPSF)
                        : 0
                    }}/sqft @ Subject Property Sqft)
                  </span>
                </div>
                <div
                  v-else
                  class="text-h5 green--text text--accent-2 pt-2 pb-4 pr-4"
                >
                  Not Available
                </div>
                <p
                  class="mb-1"
                  v-if="
                    this.attomDataObject?.neighborhoodCommunityData.demographics
                  "
                >
                  Healthcare Assistance:
                  {{
                    this.attomDataObject?.neighborhoodCommunityData.demographics
                      ?.employee_Health_Care_Social_Assistance_Naics_Pct
                  }}%
                </p>

                <p class="orange--text">
                  Employed RE:
                  {{
                    this.attomDataObject?.neighborhoodCommunityData.demographics
                      ?.emp_Real_Pct
                  }}% Employed Tech Science:
                  {{
                    this.attomDataObject?.neighborhoodCommunityData.demographics
                      ?.establishment_Professional_Scientific_Technical_Svcs_Naics_Pct
                  }}%
                </p>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </div>

      <v-expansion-panels>
        <v-expansion-panel>
          <v-expansion-panel-header>
            <div class="text-subtitle-1 white--text">All Data</div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-expansion-panels>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <div class="text-subtitle-1 white--text">
                    Application Data
                  </div>
                  <div class="text-right">
                    <v-btn
                      @click="clicked"
                      v-if="editMode"
                      color="white"
                      class="mr-3"
                      x-small
                      outlined
                      v-bind="attrs"
                      v-on="on"
                    >
                      Edit Application
                    </v-btn>
                    <v-btn
                      @click="saveApplication"
                      v-else
                      color="white"
                      class="mr-3"
                      x-small
                      outlined
                      v-bind="attrs"
                      v-on="on"
                    >
                      Save Application
                    </v-btn>
                  </div>
                </v-expansion-panel-header>

                <v-expansion-panel-content>
                  <div v-if="mappedLosObj != null" class="pt-5">
                    <v-row>
                      <v-col
                        lg="6"
                        v-for="(propertyName, i) in Object.keys(mappedLosObj)"
                        :key="i"
                      >
                        <v-text-field
                          :value="mappedLosObj[propertyName]"
                          :label="propertyName"
                          outlined
                          :readonly="editMode"
                          v-model="mappedLosObj[propertyName]"
                          dense
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel>
                <v-expansion-panel-header>
                  <div class="text-subtitle-1 white--text">Investor Data</div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <div v-if="loanApproval">
                    <div class="mt-3">
                      <div class="pb-2"><b>Scoring Output</b></div>

                      <v-row>
                        <v-col lg="2">
                          <div class="orange--text mb-1">
                            Credit Score Result:
                            <ul>
                              <li>
                                {{
                                  this.loanPreApprovalData.loanApproval.results.credit_score_result.toFixed(
                                    2
                                  )
                                }}
                              </li>

                              <li>
                                {{ this.losObject.creditScore }}
                              </li>
                            </ul>
                          </div>
                        </v-col>

                        <v-col lg="2">
                          <div class="orange--text mb-1">
                            Experience Result:
                            <ul>
                              <li>
                                {{
                                  this.loanPreApprovalData.loanApproval.results.experience_result.toFixed(
                                    2
                                  )
                                }}
                              </li>
                              <li>
                                {{ this.losObject.renovationProjects }}
                              </li>
                            </ul>
                          </div>
                        </v-col>

                        <v-col lg="2">
                          <div class="orange--text mb-1">
                            Liquidity Result:
                            <ul>
                              <li>
                                {{
                                  this.loanPreApprovalData.loanApproval.results.liquidity_result.toFixed(
                                    2
                                  )
                                }}
                              </li>
                              <li>
                                {{ this.losObject.liquidityAssets }}
                              </li>
                            </ul>
                          </div>
                        </v-col>

                        <v-col lg="2">
                          <div class="orange--text mb-1">
                            Loan-to-value Result:
                            <ul>
                              <li>
                                {{
                                  this.loanPreApprovalData.loanApproval.results.loan_to_value_result.toFixed(
                                    2
                                  )
                                }}
                              </li>
                              <li>
                                {{ this.loanToValue }}
                              </li>
                            </ul>
                          </div>
                        </v-col>

                        <v-col lg="2">
                          <div class="orange--text mb-1">
                            Loan-to-cost Result:
                            <ul>
                              <li>
                                {{
                                  this.loanPreApprovalData.loanApproval.results.ltc_result.toFixed(
                                    2
                                  )
                                }}
                              </li>
                              <li>
                                {{ this.loanToCost }}
                              </li>
                            </ul>
                          </div>
                        </v-col>
                      </v-row>
                    </div>

                    <div class="mt-5">
                      <div><b>Scoring Rules</b></div>
                      Our 5-stage pre-approval system is based on years of
                      experience in the lending industry. Credit score,
                      liquidity, loan-to-value ratio, deal experience, and
                      loan-to-cost ratio all contribute a maximum score of five
                      points each, for a total possible application score of 25
                      points. A minimum score of 15/25 is required to advance a
                      loan application. In addition, certain disqualifying
                      factors may nullify otherwise acceptable applications:

                      <ul>
                        <li>Credit score must be at least 580</li>
                        <li>
                          Liquidity must be at least ${{
                            format$Currency(
                              this.losObject["bankStatementAmount"]
                            )
                          }}
                        </li>
                        <li>Loan-to-cost must be less than or equal to 90%</li>
                        <li>Loan-to-value must be less than or equal to 70%</li>
                      </ul>
                      <br />

                      <div><b>Scoring Composite</b></div>
                      <v-row>
                        <v-col lg="2">
                          Credit Score:
                          <ul>
                            <li>600-650: 2</li>
                            <li>650-700: 3</li>
                            <li>700-750: 4</li>
                            <li>750+: 5</li>
                          </ul>
                        </v-col>

                        <v-col lg="2">
                          Experience:
                          <ul>
                            <li>0: 1.5</li>
                            <li>1: 2.5</li>
                            <li>2: 3.5</li>
                            <li>3: 4.5</li>
                            <li>4+: 5</li>
                          </ul>
                        </v-col>

                        <v-col lg="2">
                          Liquidity:
                          <ul>
                            <li>$50,000: 1</li>
                            <li>$65,000: 2</li>
                            <li>$90,000: 3</li>
                            <li>$125,000: 4</li>
                            <li>$185,000+: 5</li>
                          </ul>
                        </v-col>

                        <v-col lg="2">
                          LTV:
                          <ul>
                            <li>70%: 3</li>
                            <li>65%: 4</li>
                            <li>&lt;52%: 5</li>
                          </ul>
                        </v-col>

                        <v-col lg="2">
                          LTC:
                          <ul>
                            <li>90%: 3</li>
                            <li>87%: 4</li>
                            <li>&lt;80%: 5</li>
                          </ul>
                        </v-col>
                      </v-row>
                    </div>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel>
                <v-expansion-panel-header>
                  <div class="text-subtitle-1 white--text">
                    Subject Property Data
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <div>
                    <div>
                      <v-sheet :elevation="5" dark class="pa-5">
                        <div class="text-subtitle-2 pb-3">
                          Keystone Formula Breakdown
                        </div>
                        <div>
                          <div v-if="this.losObject">
                            {{ this.losObject.street }},
                            {{ this.losObject.city }},
                            {{ this.losObject.state }},
                            {{ this.losObject.zip }}
                          </div>
                          <div>
                            {{ totalBedrooms }} bed, {{ totalBathrooms }} bath
                          </div>
                          <div>Structure: {{ propertySize }} sqft</div>
                          <div>
                            Lot:
                            {{ Lot }} sqft
                          </div>

                          <div class="mt-5">
                            Purchase Price:
                            {{ formatStrCurrency(purchasePrice) }}
                          </div>
                          <div>Rehab: {{ formatStrCurrency(rehabBudget) }}</div>
                          <div>Investor ARV: {{ formatCurrency(ARV) }}</div>
                          <div>
                            Purchase Price PSF (PP / sqft) :
                            {{
                              this.losObject &&
                              this.losObject["purchasePricePSF"] !== undefined
                                ? this.formatKCurrency(
                                    this.losObject["purchasePricePSF"]
                                  )
                                : 0
                            }}
                          </div>
                          <div>
                            COUNT where PP/sqft > AVM/sqft (data: 50 closest
                            properties):
                            {{
                              this.losObject &&
                              this.losObject["propertyCount"] !== undefined
                                ? this.losObject["propertyCount"]
                                : 0
                            }}
                          </div>
                          <div>
                            Rehab Budget PSF (RB / sqft) :
                            {{
                              this.losObject &&
                              this.losObject["rehabBudgetPSF"] !== undefined
                                ? this.formatKCurrency(
                                    this.losObject["rehabBudgetPSF"]
                                  )
                                : 0
                            }}
                          </div>
                          <div>
                            Investor ARV PSF (IARV / sqft) :
                            {{
                              this.losObject &&
                              this.losObject["investorARVPSF"] !== undefined
                                ? this.formatKCurrency(
                                    this.losObject["investorARVPSF"]
                                  )
                                : 0
                            }}
                          </div>
                          <div>
                            ATTOM AVM PSF (AVM / sqft) :
                            {{
                              this.losObject &&
                              this.losObject["attomAVMPSF"] !== undefined
                                ? this.formatKCurrency(
                                    this.losObject["attomAVMPSF"]
                                  )
                                : 0
                            }}
                          </div>
                          <div>
                            ATTOM AVM: {{ formatCurrency(this.avmAmount) }}
                          </div>
                          <div v-if="avmProperty">
                            ATTOM AVM Value Range: (
                            {{ formatKCurrency(this.avmAmountLow) }}
                            -
                            {{ formatKCurrency(this.avmAmountHigh) }}
                            )
                          </div>
                          <div>
                            ATTOM Average Comp/Sqft: ${{ this.avgCompAvmPsf }}
                          </div>
                          <div>
                            ATTOM Assessment Value:
                            {{ formatCurrency(this.assessmentValue) }}
                          </div>
                          <div>
                            ATTOM Assessment Psf:
                            {{ formatCurrency(this.assessmentValuePSF) }}
                          </div>
                          <div>
                            Days on Market Prediction (Model: MLS):
                            {{ this.predicted_days_on_market }}
                          </div>
                          <div class="d-flex mt-2">
                            <div>
                              <div>
                                Keystone variant 4 (Average of top 2-4 comps and
                                investor ARV)
                              </div>
                            </div>
                            <v-spacer></v-spacer>
                            <div>
                              <div>
                                {{ formatCurrency(this.keystoneV3medianV2) }}
                              </div>
                            </div>
                          </div>

                          <div class="d-flex mt-2">
                            <div>
                              <div>
                                Keystone variant 4 Range (Average of top 2-4
                                comps and investor ARV)
                              </div>
                            </div>
                            <v-spacer></v-spacer>
                            <div>
                              <div>
                                {{ formatKCurrency(this.keystoneV3lowV2) }} -
                                {{ formatKCurrency(this.keystoneV3highV2) }}
                              </div>
                            </div>
                          </div>

                          <div class="d-flex mt-2">
                            <div>
                              <div>
                                A1= (AVM/sqft Average of Top 3 * Subject
                                Property Sqft)
                              </div>
                            </div>
                            <v-spacer></v-spacer>
                            <div>
                              <div>
                                {{
                                  formatKCurrency(
                                    losObject?.proxseyV2Result?.A?.A1 ?? 0
                                  )
                                }}
                              </div>
                            </div>
                          </div>
                          <div class="d-flex mt-2">
                            <div>
                              <div>
                                A2= (AVM/sqft Median of the 10 * Subject
                                Property Sqft)
                              </div>
                            </div>
                            <v-spacer></v-spacer>
                            <div>
                              <div>
                                {{
                                  formatKCurrency(
                                    losObject?.proxseyV2Result?.A?.A2 ?? 0
                                  )
                                }}
                              </div>
                            </div>
                          </div>
                          <div class="d-flex mt-2">
                            <div>
                              <div>
                                A3= (AVM/sqft Average of Bottom 3 * Subject
                                Property Sqft)
                              </div>
                            </div>
                            <v-spacer></v-spacer>
                            <div>
                              <div>
                                {{
                                  formatKCurrency(
                                    losObject?.proxseyV2Result?.A?.A3 ?? 0
                                  )
                                }}
                              </div>
                            </div>
                          </div>
                          <div class="d-flex mt-2">
                            <div>
                              <div>
                                B1= (AVM/sqft Average of Top 3 * Subject
                                Property Sqft)
                              </div>
                            </div>
                            <v-spacer></v-spacer>
                            <div>
                              <div>
                                {{
                                  formatKCurrency(
                                    losObject?.proxseyV2Result?.B?.B1 ?? 0
                                  )
                                }}
                              </div>
                            </div>
                          </div>
                          <div class="d-flex mt-2">
                            <div>
                              <div>
                                B2= (AVM/sqft Median of the 20 * Subject
                                Property Sqft)
                              </div>
                            </div>
                            <v-spacer></v-spacer>
                            <div>
                              <div>
                                {{
                                  formatKCurrency(
                                    losObject?.proxseyV2Result?.B?.B2 ?? 0
                                  )
                                }}
                              </div>
                            </div>
                          </div>
                          <div class="d-flex mt-2">
                            <div>
                              <div>
                                B3= (AVM/sqft Average of Bottom 3 * Subject
                                Property Sqft)
                              </div>
                            </div>
                            <v-spacer></v-spacer>
                            <div>
                              <div>
                                {{
                                  formatKCurrency(
                                    losObject?.proxseyV2Result?.B?.B3 ?? 0
                                  )
                                }}
                              </div>
                            </div>
                          </div>
                          <div class="d-flex mt-2">
                            <div>
                              <div>
                                C1= (AVM/sqft Average of Top 3 * Subject
                                Property Sqft)
                              </div>
                            </div>
                            <v-spacer></v-spacer>
                            <div>
                              <div>
                                {{
                                  formatKCurrency(
                                    losObject?.proxseyV2Result?.C?.C1 ?? 0
                                  )
                                }}
                              </div>
                            </div>
                          </div>
                          <div class="d-flex mt-2">
                            <div>
                              <div>
                                C2= (AVM/sqft Median of the 30 * Subject
                                Property Sqft)
                              </div>
                            </div>
                            <v-spacer></v-spacer>
                            <div>
                              <div>
                                {{
                                  formatKCurrency(
                                    losObject?.proxseyV2Result?.C?.C2 ?? 0
                                  )
                                }}
                              </div>
                            </div>
                          </div>
                          <div class="d-flex mt-2">
                            <div>
                              <div>
                                C3= (AVM/sqft Average of Bottom 3 * Subject
                                Property Sqft)
                              </div>
                            </div>
                            <v-spacer></v-spacer>
                            <div>
                              <div>
                                {{
                                  formatKCurrency(
                                    losObject?.proxseyV2Result?.C?.C3 ?? 0
                                  )
                                }}
                              </div>
                            </div>
                          </div>
                          <div class="d-flex mt-2">
                            <div>
                              <div>
                                Proxsey V2 ARV (Sellable ARV): ((A1*3) +
                                (B1*2.5) + (C1*2)) / 7.5
                              </div>
                            </div>
                            <v-spacer></v-spacer>
                            <div>
                              <div>
                                {{
                                  formatKCurrency(losObject?.proxseyV2ARV ?? 0)
                                }}
                              </div>
                            </div>
                          </div>
                          <div class="d-flex mt-2">
                            <div>
                              <div>
                                Emergency Exit: ((A2*3) + (B2*2.5) + (C2*2)) /
                                7.5
                              </div>
                            </div>
                            <v-spacer></v-spacer>
                            <div>
                              <div>
                                {{
                                  formatKCurrency(losObject?.emergencyExit ?? 0)
                                }}
                              </div>
                            </div>
                          </div>
                          <div class="d-flex mt-2">
                            <div>
                              <div>
                                Optimal Buy Price: ((A3*3) + (B3*2.5) + (C3*2))
                                / 7.5
                              </div>
                            </div>
                            <v-spacer></v-spacer>
                            <div>
                              <div>
                                {{
                                  formatKCurrency(
                                    losObject?.optimalBuyPrice ?? 0
                                  )
                                }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </v-sheet>
                    </div>

                    <div class="pt-10">
                      <div class="text-subtitle-2 pb-3">Sales Comparables</div>
                      <v-row class="justify-center">
                        <v-col style="width: 50px">
                          <v-card color="grey darken-3">
                            <v-card-text>
                              <div>Avg Comp AVM</div>
                              <p class="text-h5 text--primary">
                                {{ formatCurrency(this.avgCompAvm) }}
                              </p>
                            </v-card-text>
                          </v-card>
                        </v-col>

                        <v-col style="width: 50px">
                          <v-card color="grey darken-3">
                            <v-card-text>
                              <div>Avg Comp Sqft</div>
                              <p class="text-h5 text--primary">
                                {{ this.avgCompSqft.toFixed(0) }}
                              </p>
                            </v-card-text>
                          </v-card>
                        </v-col>

                        <v-col style="width: 50px">
                          <v-card color="grey darken-3">
                            <v-card-text>
                              <div>Avg Comp AVM PSF</div>
                              <p class="text-h5 text--primary">
                                {{ this.formatCurrency(this.avgCompAvmPsf) }}
                              </p>
                            </v-card-text>
                          </v-card>
                        </v-col>

                        <v-col style="width: 50px">
                          <v-card color="grey darken-3">
                            <v-card-text>
                              <div>Avg Comp Assessment</div>
                              <p class="text-h5 text--primary">
                                {{
                                  this.formatCurrency(this.avgCompAssessment)
                                }}
                              </p>
                            </v-card-text>
                          </v-card>
                        </v-col>

                        <v-col style="width: 50px">
                          <v-card color="grey darken-3">
                            <v-card-text>
                              <div>Avg Comp Assessment PSF</div>
                              <p class="text-h5 text--primary">
                                {{
                                  this.formatCurrency(this.avgCompAssessmentPsf)
                                }}
                              </p>
                            </v-card-text>
                          </v-card>
                        </v-col>
                      </v-row>

                      <v-sheet :elevation="5" dark class="pa-5 mt-10">
                        <p class="mb-1">
                          {{
                            this.keystoneData && this.keystoneData.saleObj
                              ? this.keystoneData.saleObj.length - 2
                              : 0
                          }}
                          sales comparables found within
                          {{ this.mile }} mile that have sold within the last 36
                          months with the following property characteristics
                          variance from the subject property: 10 years built,
                          {{ this.sqFeetRange }} square feet living size,
                          {{ this.bedroomsRange }}
                          bedroom,
                          {{ this.bathroomRange }}
                          bathroom.
                        </p>
                        <div v-if="this.saleObj">
                          <div
                            v-for="(value, i) in this.saleObj"
                            :key="i"
                            :elevation="5"
                          >
                            <v-sheet
                              v-if="value['Address'] && value.isSubjectProperty"
                              dark
                              color="green darken-3"
                              class="pa-5 mt-5"
                            >
                              <div>
                                <span>Property Details:</span>
                                <div>
                                  AVM Value:
                                  <b>{{ formatCurrency(value["AVMValue"]) }}</b>
                                </div>
                                <div>
                                  AVM PSF:
                                  <b>{{ formatCurrency(value["AVMPSF"]) }}</b>
                                </div>
                                <div>
                                  Assessment Value:
                                  <b>{{
                                    formatCurrency(value["assessmentValue"])
                                  }}</b>
                                </div>
                                <div>
                                  Assessment PSF Value:
                                  <b>{{
                                    formatCurrency(value["assessmentValuePSF"])
                                  }}</b>
                                </div>
                                <div>
                                  Square Footage:
                                  <b>{{
                                    value["GrossLivingAreaSquareFeetCount"]
                                  }}</b>
                                </div>
                                <div>
                                  PSF from Latest Sales Transaction:
                                  <b>{{
                                    formatCurrency(
                                      value["PricePerSquareFootAmount"]
                                    )
                                  }}</b>
                                </div>

                                <div>
                                  Address:
                                  <b>{{ value["Address"] }}</b>
                                </div>
                              </div>
                            </v-sheet>

                            <v-sheet
                              v-else-if="value['Address']"
                              dark
                              color="grey darken-3"
                              class="pa-5 mt-5"
                            >
                              <div>
                                <span>Property Details:</span>
                                <div>
                                  AVM Value:
                                  <b>{{ formatCurrency(value["AVMValue"]) }}</b>
                                </div>
                                <div>
                                  AVM PSF:
                                  <b>{{ formatCurrency(value["AVMPSF"]) }}</b>
                                </div>
                                <div>
                                  Assessment Value:
                                  <b>{{
                                    formatCurrency(value["assessmentValue"])
                                  }}</b>
                                </div>
                                <div>
                                  Assessment PSF Value:
                                  <b>{{
                                    formatCurrency(value["assessmentValuePSF"])
                                  }}</b>
                                </div>
                                <div>
                                  Square Footage:
                                  <b>{{
                                    value["GrossLivingAreaSquareFeetCount"]
                                  }}</b>
                                </div>
                                <div>
                                  PSF from Latest Sales Transaction:
                                  <b>{{
                                    formatCurrency(
                                      value["PricePerSquareFootAmount"]
                                    )
                                  }}</b>
                                </div>

                                <div>
                                  Address:
                                  <b>{{ value["Address"] }}</b>
                                </div>
                              </div>
                            </v-sheet>
                          </div>
                        </div>
                      </v-sheet>
                    </div>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel>
                <v-expansion-panel-header>
                  <div class="text-subtitle-1 white--text">Deal Data</div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-sheet :elevation="5" dark class="pa-5">
                    <div class="text-subtitle-2 pb-3">
                      The Deal Formula Breakdown
                    </div>

                    <div class="d-flex mt-5">
                      <div>
                        <div>
                          Confidence (Keystone Median/(Purchase Price + Rehab
                          Budget) =
                          {{
                            this.viabilityData && this.viabilityData.confidence
                              ? this.viabilityData.confidence
                              : 0
                          }}% )
                        </div>
                        <div>Exposure (Loan amount % of Keystone)</div>
                        <div>
                          Investor Gross Profit ({{ profitDollarAmount }})
                        </div>
                      </div>
                      <v-spacer></v-spacer>
                      <div>
                        <div>
                          {{
                            this.viabilityData && this.viabilityData.confidence
                              ? this.viabilityData.confidence
                              : 0
                          }}
                        </div>
                        <div>
                          {{
                            this.viabilityData && this.viabilityData.exposure
                              ? this.viabilityData.exposure
                              : 0
                          }}%
                        </div>
                        <div>
                          {{
                            this.dealData && this.dealData.profitPercentage
                              ? this.dealData.profitPercentage
                              : 0
                          }}%
                        </div>
                      </div>
                    </div>
                  </v-sheet>

                  <v-sheet :elevation="5" dark class="pa-5 mt-10">
                    <div class="text-subtitle-2 pb-5">
                      Deal Profit Calculator
                    </div>
                    <div v-if="loanApproval">
                      <v-row>
                        <v-col lg="6">
                          <v-text-field
                            :value="purchasePrice"
                            label="Purchase Price ($)"
                            outlined
                            dense
                            prefix="$"
                            disabled
                          ></v-text-field>

                          <v-text-field
                            :value="rehabBudget"
                            label="Rehab Budget ($)"
                            outlined
                            dense
                            prefix="$"
                            disabled
                          ></v-text-field>

                          <v-text-field
                            :value="totalAcquisitionCost"
                            label="Total Acquisition Cost ($)"
                            outlined
                            dense
                            prefix="$"
                            disabled
                          ></v-text-field>

                          <v-text-field
                            :value="ARV"
                            label="ARV"
                            outlined
                            dense
                            prefix="$"
                            disabled
                          ></v-text-field>

                          <v-text-field
                            :value="avmAmount"
                            label="AVM ($)"
                            outlined
                            prefix="$"
                            disabled
                            dense
                          ></v-text-field>

                          <v-text-field
                            :value="loanAmount"
                            label="Loan Amount ($)"
                            outlined
                            dense
                            prefix="$"
                            disabled
                          ></v-text-field>

                          <v-text-field
                            :value="downPayment"
                            label="Down Payment ($)"
                            outlined
                            dense
                            prefix="$"
                            disabled
                          ></v-text-field>

                          <v-text-field
                            :value="interestAmount"
                            label="Interest Payments ($)"
                            outlined
                            dense
                            prefix="$"
                            disabled
                          ></v-text-field>

                          <v-text-field outlined dense disabled></v-text-field>

                          <v-text-field
                            label="Loan Points ($)"
                            :value="loanPointsAmount"
                            outlined
                            dense
                            prefix="$"
                            disabled
                          ></v-text-field>

                          <v-text-field
                            label="BPO Appraisal, Attorney Review & Doc Review Fee"
                            :value="bpoAppraisalValue"
                            outlined
                            dense
                            prefix="$"
                            disabled
                          ></v-text-field>

                          <v-text-field
                            :value="realtorAmount"
                            label="Realtor Fees ($)"
                            outlined
                            dense
                            prefix="$"
                            disabled
                          ></v-text-field>

                          <v-text-field
                            :value="propertyTaxes"
                            label="Taxes ($)"
                            outlined
                            dense
                            prefix="$"
                            disabled
                          ></v-text-field>

                          <div>
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-bind="attrs"
                                  v-on="on"
                                  :value="propertyInsurance"
                                  label="Homeowner Insurance ($)"
                                  outlined
                                  dense
                                  prefix="$"
                                  disabled
                                ></v-text-field>
                              </template>
                              <span
                                >Total Acquisition Cost*((Loan Term
                                Months/12)*Insurance Rate)</span
                              >
                            </v-tooltip>
                          </div>

                          <v-text-field
                            label="Title Insurance ($)"
                            :value="titleInsuranceAmount"
                            outlined
                            dense
                            prefix="$"
                            disabled
                          ></v-text-field>

                          <v-text-field
                            label="Government Recording Fees & Daily Interest"
                            :value="governmentFee"
                            outlined
                            dense
                            prefix="$"
                            disabled
                          ></v-text-field>

                          <v-text-field
                            label="Warehouse Investor Fee"
                            :value="warehouseInvestorFee"
                            outlined
                            dense
                            prefix="$"
                            disabled
                          ></v-text-field>

                          <v-text-field
                            :value="utilities"
                            label="Utilities ($)"
                            outlined
                            dense
                            prefix="$"
                            disabled
                          ></v-text-field>

                          <v-text-field
                            :value="drawFees"
                            label="Draw Fees ($)"
                            outlined
                            dense
                            prefix="$"
                            disabled
                          ></v-text-field>
                        </v-col>

                        <v-col lg="6">
                          <v-text-field
                            :value="propertySize"
                            label="Square Footage"
                            outlined
                            disabled
                            dense
                          ></v-text-field>

                          <v-text-field outlined dense disabled></v-text-field>

                          <v-text-field
                            :value="acquisitionCostPsf"
                            label="Total Acquisition Cost PSF ($)"
                            outlined
                            dense
                            prefix="$"
                            disabled
                          ></v-text-field>

                          <v-text-field
                            :value="arvPsfValue"
                            label="ARV PSF ($)"
                            outlined
                            dense
                            prefix="$"
                            disabled
                          ></v-text-field>

                          <v-text-field
                            :value="avmPsfValue"
                            label="AVM PSF ($)"
                            outlined
                            dense
                            prefix="$"
                            disabled
                          ></v-text-field>

                          <v-text-field outlined dense disabled></v-text-field>

                          <v-text-field outlined dense disabled></v-text-field>

                          <v-text-field
                            :value="loanTermMonths"
                            label="Loan Term Months"
                            outlined
                            dense
                            disabled
                          ></v-text-field>

                          <v-text-field
                            :value="interestRate"
                            label="Interest Rate"
                            outlined
                            dense
                            prefix="%"
                            disabled
                          ></v-text-field>

                          <v-text-field
                            label="Loan Points %"
                            :value="loanPointsPercent"
                            outlined
                            dense
                            prefix="%"
                            disabled
                          ></v-text-field>

                          <v-text-field outlined dense disabled></v-text-field>

                          <v-text-field
                            :value="realtorRate"
                            label="Realtor Rate"
                            outlined
                            dense
                            prefix="%"
                            disabled
                          ></v-text-field>

                          <v-text-field
                            :value="taxRate"
                            label="Tax Rate"
                            outlined
                            dense
                            prefix="%"
                            disabled
                          ></v-text-field>

                          <v-text-field
                            :value="insuranceRate"
                            label="Homeowner Insurance Rate"
                            outlined
                            dense
                            prefix="%"
                            disabled
                          ></v-text-field>

                          <v-text-field
                            label="Title Insurance Rate"
                            :value="titleInsurancePercent"
                            outlined
                            dense
                            prefix="%"
                            disabled
                          ></v-text-field>

                          <v-text-field outlined dense disabled></v-text-field>

                          <v-text-field outlined dense disabled></v-text-field>

                          <v-text-field outlined dense disabled></v-text-field>

                          <v-text-field outlined dense disabled></v-text-field>
                        </v-col>
                      </v-row>

                      <div class="mt-5">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-bind="attrs"
                              v-on="on"
                              :value="totalCostBasis"
                              label="Total Cost Basis ($)"
                              outlined
                              dense
                              disabled
                              prefix="$"
                            ></v-text-field>
                          </template>
                          <span
                            >SUM(Total Acquisition Cost+Interest
                            Payments+Realtor Fees+Taxes+Insurance+Utilities+Draw
                            Fees)</span
                          >
                        </v-tooltip>
                      </div>

                      <v-text-field
                        :value="profitDollarAmount"
                        label="Profit ($)"
                        outlined
                        dense
                        disabled
                        prefix="$"
                      ></v-text-field>

                      <v-text-field
                        :value="profitPercentage"
                        label="Profit Percentage (%)"
                        outlined
                        dense
                        prefix="%"
                        disabled
                      ></v-text-field>
                    </div>
                  </v-sheet>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel
                v-if="losObject.platform && losObject.platform == 'mobile'"
              >
                <v-expansion-panel-header>
                  <div class="text-subtitle-1 white--text">Documents</div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-sheet :elevation="5" dark class="pa-5">
                    <div>
                      <!-- Business Documents Section -->
                      <v-row>
                        <v-col>
                          <div class="text-subtitle-3 pb-5">
                            Business Documents
                          </div>
                          <v-btn
                            v-for="(url, key) in businessDocument"
                            :key="key"
                            @click="openDocument(url)"
                            dense
                            class="mt-3"
                            style="width: 400px"
                          >
                            {{ key.replace(/_/g, " ") }}
                          </v-btn>
                        </v-col>

                        <v-col>
                          <div class="text-subtitle-3 pb-5">
                            Personal Documents
                          </div>
                          <v-btn
                            v-for="(url, key) in personalDocument"
                            :key="key"
                            @click="openDocument(url)"
                            class="mt-3 mr-8"
                            style="width: 400px"
                          >
                            {{ key.replace(/_/g, " ") }}
                          </v-btn>
                        </v-col>

                        <v-col>
                          <div class="text-subtitle-3 pb-5">
                            Property Documents
                          </div>
                          <v-btn
                            v-for="(url, key) in propertyDocument"
                            :key="key"
                            @click="openDocument(url)"
                            dense
                            class="mt-3 mr- 10"
                            style="width: 400px"
                          >
                            {{ key.replace(/_/g, " ") }}
                          </v-btn>
                        </v-col>
                      </v-row>
                    </div>
                  </v-sheet>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel
                v-if="losObject.platform && losObject.platform == 'mobile'"
              >
                <v-expansion-panel-header>
                  <div class="text-subtitle-1 white--text">
                    Rehab Budget Data
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-sheet :elevation="5" dark class="pa-5">
                    <v-row>
                      <v-col
                        lg="6"
                        v-for="(value, propertyName, i) in rehabBudgetData"
                        :key="i"
                      >
                        <v-text-field
                          :value="formatStrCurrency(value)"
                          :label="propertyName"
                          outlined
                          :readonly="editMode"
                          dense
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-sheet>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <v-sheet :elevation="5" dark class="pa-5 mt-10">
        <div class="text-subtitle-1 white--text pb-5">
          Sales Comparables JSON Data
        </div>

        <div v-if="!this.loading && this.salesComparables" class="code-details">
          <MonacoEditor
            v-model="salesComparables"
            width="100%"
            height="400"
            theme="vs-dark"
            language="json"
            :options="options"
          ></MonacoEditor>
        </div>

        <div
          v-else-if="!this.loading && this.losObject.isProcessApplication"
          class="mt-15 green--text"
        >
          Sales Comparables data is empty.
        </div>
      </v-sheet>

      <v-sheet :elevation="5" dark class="pa-5 mt-10">
        <div class="text-subtitle-1 white--text pb-5">
          Subject Property Street View
        </div>
        <!-- <div id="street_view_map_div"></div> -->
        <div
          v-if="subjectProperty && subjectProperty.Address"
          class="pa-5 mt-10"
        >
          <v-sheet :elevation="5" dark>
            <div id="pano"></div>
            <div id="map"></div>
          </v-sheet>
        </div>

        <div
          v-else-if="!this.loading && this.losObject.isProcessApplication"
          class="mt-15 pb--1 green--text"
        >
          Subject Property Address is empty.
        </div>
      </v-sheet>
      <v-sheet
        :elevation="5"
        dark
        class="pa-5 mt-https://os.crebrid.com/los/loans/JO4v2hFKQG18ij4jDlpJ10"
      >
        <div
          v-if="
            this.losObject &&
            this.subjectProperty &&
            this.attomDataObject.closestPropertiesFullData
          "
        >
          <MapComponent
            :loan="this.losObject"
            :subjectProperty="this.subjectProperty"
            :closestPropertyObj="this.attomDataObject.closestPropertiesFullData"
            :zoomLevel="15"
          />
        </div>
      </v-sheet>

      <v-sheet :elevation="5" dark class="pa-5 mt-10">
        <div class="text-subtitle-1 white--text pb-5">
          50 Closest AVM Properties JSON Full Data
          <!-- {{this.avmSnapShot}} -->
        </div>

        <div
          v-if="!this.loading && this.closestPropertiesFullData"
          class="code-details"
        >
          <MonacoEditor
            v-model="this.closestPropertiesFullData"
            width="100%"
            height="400"
            theme="vs-dark"
            language="json"
            :options="options"
          ></MonacoEditor>
        </div>
        <div
          v-else-if="!this.loading && this.losObject.isProcessApplication"
          class="mt-15 green--text"
        >
          50 Closest AVM Properties JSON Full Data is empty.
        </div>
      </v-sheet>

      <v-sheet :elevation="5" dark class="pa-5 mt-10">
        <div class="text-subtitle-1 white--text pb-5">
          50 Closest AVM Properties JSON Min Data
          <!-- {{this.avmSnapShot}} -->
        </div>

        <div
          v-if="!this.loading && this.closestPropertiesMinData"
          class="code-details"
        >
          <MonacoEditor
            v-model="this.closestPropertiesMinData"
            width="100%"
            height="400"
            theme="vs-dark"
            language="json"
            :options="options"
          ></MonacoEditor>
        </div>
        <div
          v-else-if="!this.loading && this.losObject.isProcessApplication"
          class="mt-15 green--text"
        >
          50 Closest AVM Properties JSON Min Data is empty.
        </div>
      </v-sheet>

      <v-sheet :elevation="5" dark class="pa-5 mt-10">
        <div class="text-subtitle-1 white--text pb-5">
          Subject Property AVM Details JSON Data
        </div>

        <div
          v-if="!this.loading && this.subjectPropertyAVMDetail"
          class="code-details"
        >
          <MonacoEditor
            v-model="this.subjectPropertyAVMDetail"
            width="100%"
            height="400"
            theme="vs-dark"
            language="json"
            :options="options"
          ></MonacoEditor>
        </div>
        <div
          v-else-if="!this.loading && this.losObject.isProcessApplication"
          class="mt-15 green--text"
        >
          Subject Property AVM Details data is empty.
        </div>
      </v-sheet>

      <v-sheet :elevation="5" dark class="pa-5 mt-10">
        <div class="text-subtitle-1 white--text pb-5">
          AVM Details JSON Data
        </div>

        <div v-if="!this.loading && this.attomAvmDetail" class="code-details">
          <MonacoEditor
            v-model="this.attomAvmDetail"
            width="100%"
            height="400"
            theme="vs-dark"
            language="json"
            :options="options"
          ></MonacoEditor>
        </div>
        <div
          v-else-if="!this.loading && this.losObject.isProcessApplication"
          class="mt-15 green--text"
        >
          AVM Details data is empty.
        </div>
      </v-sheet>

      <v-sheet :elevation="5" dark class="pa-5 mt-10">
        <div class="text-subtitle-1 white--text pb-5">
          Sales Comparable Property 1 AVM Details JSON Data
        </div>

        <div v-if="!this.loading && this.compProp1Avm" class="code-details">
          <MonacoEditor
            v-model="this.compProp1Avm"
            width="100%"
            height="400"
            theme="vs-dark"
            language="json"
            :options="options"
          ></MonacoEditor>
        </div>
        <div
          v-else-if="!this.loading && this.losObject.isProcessApplication"
          class="mt-15 green--text"
        >
          Sales Comparable Property 1 AVM Details data is empty.
        </div>
      </v-sheet>

      <v-sheet :elevation="5" dark class="pa-5 mt-10">
        <div class="text-subtitle-1 white--text pb-5">
          Sales Comparable Property 2 AVM Details JSON Data
        </div>

        <div v-if="!this.loading && this.compProp2Avm" class="code-details">
          <MonacoEditor
            v-model="this.compProp2Avm"
            width="100%"
            height="400"
            theme="vs-dark"
            language="json"
            :options="options"
          ></MonacoEditor>
        </div>
        <div
          v-else-if="!this.loading && this.losObject.isProcessApplication"
          class="mt-15 green--text"
        >
          Sales Comparable Property 2 AVM Details data is empty.
        </div>
      </v-sheet>

      <v-sheet :elevation="5" dark class="pa-5 mt-10">
        <div class="text-subtitle-1 white--text pb-5">
          Sales Comparable Property 3 AVM Details JSON Data
        </div>

        <div v-if="!this.loading && this.compProp3Avm" class="code-details">
          <MonacoEditor
            v-model="this.compProp3Avm"
            width="100%"
            height="400"
            theme="vs-dark"
            language="json"
            :options="options"
          ></MonacoEditor>
        </div>
        <div
          v-else-if="!this.loading && this.losObject.isProcessApplication"
          class="mt-15 green--text"
        >
          Sales Comparable Property 3 AVM Details data is empty.
        </div>
      </v-sheet>

      <v-sheet :elevation="5" dark class="pa-5 mt-10">
        <div class="text-subtitle-1 white--text pb-5">
          Sales Comparable Property 4 AVM Details JSON Data
        </div>

        <div v-if="!this.loading && this.compProp4Avm" class="code-details">
          <MonacoEditor
            v-model="this.compProp4Avm"
            width="100%"
            height="400"
            theme="vs-dark"
            language="json"
            :options="options"
          ></MonacoEditor>
        </div>
        <div
          v-else-if="!this.loading && this.losObject.isProcessApplication"
          class="mt-15 green--text"
        >
          Sales Comparable Property 4 AVM Details data is empty.
        </div>
      </v-sheet>

      <v-sheet :elevation="5" dark class="pa-5 mt-10">
        <div class="text-subtitle-1 white--text pb-5">
          Sales Comparable Property 5 AVM Details JSON Data
        </div>

        <div v-if="!this.loading && this.compProp5Avm" class="code-details">
          <MonacoEditor
            v-model="this.compProp5Avm"
            width="100%"
            height="400"
            theme="vs-dark"
            language="json"
            :options="options"
          ></MonacoEditor>
        </div>
        <div
          v-else-if="!this.loading && this.losObject.isProcessApplication"
          class="mt-15 green--text"
        >
          Sales Comparable Property 5 AVM Details data is empty.
        </div>
      </v-sheet>
      <v-sheet :elevation="5" dark class="pa-5 mt-10">
        <div class="text-subtitle-1 white--text pb-5">
          Sales Comparable Property 6 AVM Details JSON Data
        </div>

        <div v-if="!this.loading && this.compProp6Avm" class="code-details">
          <MonacoEditor
            v-model="this.compProp6Avm"
            width="100%"
            height="400"
            theme="vs-dark"
            language="json"
            :options="options"
          ></MonacoEditor>
        </div>
        <div
          v-else-if="!this.loading && this.losObject.isProcessApplication"
          class="mt-15 green--text"
        >
          Sales Comparable Property 6 AVM Details data is empty.
        </div>
      </v-sheet>
      <v-sheet :elevation="5" dark class="pa-5 mt-10">
        <div class="text-subtitle-1 white--text pb-5">
          Sales Comparable Property 7 AVM Details JSON Data
        </div>

        <div v-if="!this.loading && this.compProp7Avm" class="code-details">
          <MonacoEditor
            v-model="this.compProp7Avm"
            width="100%"
            height="400"
            theme="vs-dark"
            language="json"
            :options="options"
          ></MonacoEditor>
        </div>
        <div
          v-else-if="!this.loading && this.losObject.isProcessApplication"
          class="mt-15 green--text"
        >
          Sales Comparable Property 7 AVM Details data is empty.
        </div>
      </v-sheet>
      <v-sheet :elevation="5" dark class="pa-5 mt-10">
        <div class="text-subtitle-1 white--text pb-5">
          Sales Comparable Property 8 AVM Details JSON Data
        </div>

        <div v-if="!this.loading && this.compProp8Avm" class="code-details">
          <MonacoEditor
            v-model="this.compProp8Avm"
            width="100%"
            height="400"
            theme="vs-dark"
            language="json"
            :options="options"
          ></MonacoEditor>
        </div>
        <div
          v-else-if="!this.loading && this.losObject.isProcessApplication"
          class="mt-15 green--text"
        >
          Sales Comparable Property 8 AVM Details data is empty.
        </div>
      </v-sheet>
      <v-sheet :elevation="5" dark class="pa-5 mt-10">
        <div class="text-subtitle-1 white--text pb-5">
          Sales Comparable Property 9 AVM Details JSON Data
        </div>

        <div v-if="!this.loading && this.compProp9Avm" class="code-details">
          <MonacoEditor
            v-model="this.compProp9Avm"
            width="100%"
            height="400"
            theme="vs-dark"
            language="json"
            :options="options"
          ></MonacoEditor>
        </div>
        <div
          v-else-if="!this.loading && this.losObject.isProcessApplication"
          class="mt-15 green--text"
        >
          Sales Comparable Property 9 AVM Details data is empty.
        </div>
      </v-sheet>
      <v-sheet :elevation="5" dark class="pa-5 mt-10">
        <div class="text-subtitle-1 white--text pb-5">
          Sales Comparable Property 10 AVM Details JSON Data
        </div>

        <div v-if="!this.loading && this.compProp10Avm" class="code-details">
          <MonacoEditor
            v-model="this.compProp10Avm"
            width="100%"
            height="400"
            theme="vs-dark"
            language="json"
            :options="options"
          ></MonacoEditor>
        </div>
        <div
          v-else-if="!this.loading && this.losObject.isProcessApplication"
          class="mt-15 green--text"
        >
          Sales Comparable Property 10 AVM Details data is empty.
        </div>
      </v-sheet>
      <v-sheet :elevation="5" dark class="pa-5 mt-10">
        <div class="text-subtitle-1 white--text pb-5">Community Data</div>

        <div v-if="!this.loading && this.communitydata" class="code-details">
          <MonacoEditor
            v-model="this.communitydata"
            width="100%"
            height="400"
            theme="vs-dark"
            language="json"
            :options="options"
          ></MonacoEditor>
        </div>
        <div v-else class="mt-15 green--text">Community data is empty.</div>
      </v-sheet>
      <v-sheet :elevation="5" dark class="pa-5 mt-10">
        <div class="text-subtitle-1 white--text pb-5">Sales Trend Data</div>

        <div v-if="!this.loading && this.salestrenddata" class="code-details">
          <MonacoEditor
            v-model="this.salestrenddata"
            width="100%"
            height="400"
            theme="vs-dark"
            language="json"
            :options="options"
          ></MonacoEditor>
        </div>
        <div v-else class="mt-15 green--text">Sales Trend data is empty.</div>
      </v-sheet>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import MonacoEditor from "monaco-editor-vue";
import { firebaseDB, firebaseappDB } from "../../../auth/firebase-auth";
import Loader from "../../../components/Loader.vue";
import ProgressBar from "../../../components/ProgressBar.vue";
import MapComponent from "../../../components/MapComponent.vue";

export default {
  name: "Los",
  components: {
    Loader,
    MonacoEditor,
    ProgressBar,
    MapComponent,
  },
  props: ["id"],
  data() {
    return {
      rehabBudgetData: null,
      businessDocument: 0,
      personalDocument: 0,
      propertyDocument: 0,
      documents: null,
      progress: 0,
      apiDuration: 30000, // API call duration in milliseconds (e.g., 10 seconds)
      progressSteps: 4, // Number of progress steps (25% each)
      progressComplete: false,
      street_map: null,
      panorama: null,
      loading: true,
      loadingBar: false,
      dialog: false,
      aiDialog: false,
      parsedContent: [],
      color: "success",
      snackbar: false,
      snackBarText: "",
      snackbarColor: "",
      snackBarTimeout: 5000,
      disableDownload: false,
      options: {
        //Monaco Editor Options
      },
      avmProperty: "",
      subjectProperty: "",
      avgCompAvm: 0,
      avgCompSqft: 0,
      avgCompAvmPsf: 0,
      keystoneAvg2: (this.avmAmount + this.ARV) / 2,
      keystoneAvg3: "",
      keystoneAvg: "",
      minComAvmAvg: 0,
      maxComAvmAvg: 0,
      minComAvmpsf: 0,
      maxComAvmpsf: 0,
      keystoneV3high: 0,
      keystoneV3low: 0,
      keystoneV3median: 0,
      keystoneV3highV2: 0,
      keystoneV3lowV2: 0,
      keystoneV3medianV2: 0,
      LE: "",
      borrowerDialog: false,
      propertyDialog: false,
      compsDialog: false,
      los: [],
      processLoanApplicationData: {},
      losObject: null,
      attomDataObject: null,
      salesComparablesParam: null,
      aiAnalyzeData: null,
      mile: 0,
      sqFeetRange: 0,
      bedroomsRange: 0,
      bathroomRange: 0,
      loanPreApprovalData: null,
      runsData: null,
      losLogObject: null,
      mappedLosObj: null,
      saleObj: null,
      keystoneData: null,
      dealData: null,
      viabilityData: null,
      propertyBasicProfile: "",
      attomAvmDetail: "",
      subjectPropertyAVMDetail: "",
      salesComparables: "",
      salesHistoryDetail: "",
      avgAVMCom: null,
      salesComparablesCount: "",
      compProp1Avm: "",
      compProp2Avm: "",
      compProp3Avm: "",
      compProp4Avm: "",
      compProp5Avm: "",
      compProp6Avm: "",
      compProp7Avm: "",
      compProp8Avm: "",
      compProp9Avm: "",
      compProp10Avm: "",
      totalBedrooms: 0,
      totalBathrooms: 0,
      propertySize: 0,
      Lot: 0,
      avmAmount: 0,
      avmAmountLow: 0,
      avmAmountHigh: 0,
      avmPsfValue: 0,
      avmSnapShot: "",
      avmSnapShotDetails: "",
      avmDeatils: "",
      avmAssessmentSnapShot: "",
      avmAssessmentSnapShotString: "",
      confidence: 0,
      totalAcquisitionCost: 0,
      downPayment: 0,
      interestAmount: 0,
      loanPointsAmount: 0,
      realtorAmount: 0,
      propertyTaxes: 0,
      propertyInsurance: 0,
      titleInsuranceAmount: 0,
      acquisitionCostPsf: 0,
      arvPsfValue: 0,
      assessmentValue: 0,
      assessmentValuePSF: 0,
      profitPercentage: "",
      profitDollarAmount: "",
      loanAmount: "",
      totalCostBasis: "",
      purchasePrice: "$150,000",
      rehabBudget: "$25,000",
      closingCosts: "$2000",
      ARV: "$225,000",
      loanTermMonths: 0,
      interestRate: 0,
      loanPointsPercent: 0,
      bpoAppraisalValue: 0,
      titleInsurancePercent: 0,
      governmentFee: 0,
      warehouseInvestorFee: 0,
      realtorRate: 0,
      insuranceRate: 0,
      taxRate: 0,
      closingCostsRate: "0.04",
      utilities: 0,
      drawFees: 0,
      profitAmount: "",
      editMode: true,
      loanToCost: 0,
      loanToValue: 0,
      predicted_days_on_market: 0,
      communitydata: null,
      salestrenddata: null,
      neighborhoodHEValue: 0,
      executionTimeValue: "",
      attomApiCallCountValue: 0,
      executionTimeMinutes: 0,
      executionTimeSeconds: 0.0,
      totalProcessingCost: 0,
      closestPropertiesFullData: null,
      closestPropertiesMinData: null,
      subjectPropertyLat: null,
      subjectPropertyLng: null,
    };
  },

  mounted() {
    this.fetchLosDetail();
  },

  computed: {
    // totalCostBasis: function () {
    //   return (
    //     // Number(this.propertyClosingCost) +
    //     (
    //       Number(this.propertyInsurance) +
    //       Number(this.propertyTaxes) +
    //       Number(this.utilities) +
    //       Number(this.drawFees) +
    //       Number(this.purchasePrice) +
    //       Number(this.rehabBudget) +
    //       Number(this.loanPointsAmount) +
    //       Number(this.titleInsuranceAmount) +
    //       Number(this.governmentFee) +
    //       Number(this.warehouseInvestorFee) +
    //       Number(this.interestAmount)
    //     ).toLocaleString("en-US")
    //   );
    // },

    loanApproval() {
      return this.loanPreApprovalData
        ? this.loanPreApprovalData.loanApproval
        : null;
    },
    fundedLoansData() {
      return this.$store.state.fundedLoans;
    },
    inProgressData() {
      return this.$store.state.inProgressLoans;
    },
  },

  methods: {
    openDialog() {
      this.aiDialog = true;
      this.parseContent();
    },
    parseContent() {
      const rawContent = this.aiAnalyzeData.choices[0].message.content;
      this.parsedContent = this.convertToVueComponents(rawContent);
    },
    convertToVueComponents(content) {
      const lines = content.split("\n");
      const components = [];

      lines.forEach((line) => {
        line = line.trim();

        if (!line) return;

        if (line.startsWith("### ")) {
          components.push({ type: "title", content: line.replace("### ", "") });
        } else if (line.startsWith("#### ")) {
          components.push({
            type: "subtitle",
            content: line.replace("#### ", ""),
          });
        } else if (line.startsWith("- ")) {
          components.push({
            type: "bullet",
            content: this.processBoldText(line.replace("- ", "")),
          });
        } else if (line.match(/^\d+\.\s/)) {
          components.push({
            type: "numbered",
            content: this.processBoldText(line),
          });
        } else if (line.includes("**")) {
          components.push({
            type: "text",
            content: this.processBoldText(line),
          });
        } else {
          components.push({ type: "text", content: line });
        }
      });

      return components;
    },

    processBoldText(line) {
      return line.replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>");
    },
    openDocument(url) {
      window.open(url, "_blank");
    },
    filterObjectByURL(obj) {
      return Object.fromEntries(
        Object.entries(obj).filter(
          ([key, value]) => value && value.trim() !== ""
        )
      );
    },
    async saveApplication() {
      try {
        const applicationRef = await firebaseDB
          .firestore()
          .collection("loans")
          .doc(this.id);

        await applicationRef.update(this.mappedLosObj);
        this.fetchLosDetail();
        console.log("Data updated in Firebase successfully!");
        this.editMode = true;
      } catch (error) {
        console.error("Error updating data in Firebase:", error);
      }
    },
    async streetViewMap(subjectProperty) {
      this.loading = true;

      const geocoder = new google.maps.Geocoder();
      geocoder.geocode(
        { address: subjectProperty.Address },
        (results, status) => {
          if (status === "OK") {
            const subLatLong = results[0].geometry.location;
            console.log("Subject property address subLatLong: " + subLatLong);

            const mapOptions = {
              center: subLatLong,
              zoom: 14,
            };

            this.street_map = new google.maps.Map(
              document.getElementById("map"),
              mapOptions
            );

            this.panorama = new google.maps.StreetViewPanorama(
              document.getElementById("pano"),
              {
                position: subLatLong,
                pov: { heading: 0, pitch: 0 },
                zoom: 0,
              }
            );

            this.street_map.setStreetView(this.panorama);
          } else {
            console.error(
              "Geocode was not successful for the following reason: " + status
            );
          }
        }
      );
    },

    async processApplication() {
      this.loading = true;
      const token = `Bearer eyJhbGciOiJIUzUxMiIsInR5cCI6IkpXVCJ9.eyJtZXNzYWdlIjoiVG9rZW4gZm9yIGNyZWJyaWQtb3MgQVBJIiwiaWF0IjoxNjc1MzQxOTA1fQ._MKnNlPgvURchmlE1YuewP_FEfJ7rJMmVnsiR0FyfPJ_1BQAdPojeOvfwTPaegyqMFYXQN21ytPMl24Zky589A`;
      this.processLoanApplicationData["isProcessApplication"] = true;
      this.processLoanApplicationData["appId"] = this.$props.id;
      this.processLoanApplicationData["platform"] = "mobile";
      this.processLoanApplicationData["propertyAddressLat"] =
        this.losObject?.propertyAddressLat;
      this.processLoanApplicationData["propertyAddressLong"] =
        this.losObject?.propertyAddressLong;

      const url =
        "https://us-central1-crebrid-os.cloudfunctions.net/crebridApp/apply-loan";
      await axios
        .post(`${url}`, this.processLoanApplicationData, {
          headers: { Authorization: token },
        })
        .then((response) => {
          this.loading = false;
          this.snackBarText = response.data.message;
          this.snackbarColor = "green";
          this.snackbar = true;
          this.fetchLosDetail();
        })
        .catch((err) => {
          this.loading = false;
          this.snackBarText = "Something went wrong, please check the logs!";
          this.snackbarColor = "red";
          this.snackbar = true;
          console.log("Error: ", err);
        });
    },

    async fetchLosDetail() {
      this.loading = true;
      try {
        const losRef = await firebaseDB
          .firestore()
          .collection("loans")
          .doc(this.$props.id)
          .get();

        const losLogRef = await firebaseDB
          .firestore()
          .collection("loans")
          .doc(this.$props.id);

        let eventsResult = [];
        const logdata = await losLogRef.collection("logs").get();
        logdata.docs.forEach((doc) => {
          eventsResult.push(doc.data());
        });
        this.losObject = { ...losRef.data() };
        console.log("losObject-------", this.losObject);
        // Calculate the total time in minutes and seconds
        this.executionTimeValue = this.losObject.executionTime || 0;
        if (isNaN(this.executionTimeValue)) {
          this.executionTimeMinutes = 0;
          this.executionTimeSeconds = "0.00";
        } else {
          this.executionTimeMinutes = Math.floor(
            this.executionTimeValue / 60000
          );
          this.executionTimeSeconds = (
            (this.executionTimeValue % 60000) /
            1000
          ).toFixed(2);
        }

        // Calculate the total cost based on the number of ATTOM API calls
        const costPerCall = 0.2;
        this.attomApiCallCountValue =
          (this.losObject.attomApiCallCount || 0) * costPerCall;
        this.totalProcessingCost =
          this.losObject.openAiCost + this.attomApiCallCountValue;

        this.mappedLosObj = { ...losRef.data() };
        this.losLogObject = eventsResult;
        if (this.losObject.platform && this.losObject.platform == "mobile") {
          let linkData = await firebaseappDB
            .firestore()
            .collection("LoanApplications")
            .doc(this.losObject.appLoanApplicationId)
            .collection("documents")
            .doc("all_documents")
            .get();
          let rehabBudgetDoc = await firebaseappDB
            .firestore()
            .collection("LoanApplications")
            .doc(this.losObject.appLoanApplicationId)
            .collection("RehabBudgets")
            .doc("rehab_budgets_data")
            .get();
          this.rehabBudgetData = { ...rehabBudgetDoc.data() };

          this.documents = { ...linkData.data() };

          this.businessDocument = this.documents.businessDocument
            ? this.filterObjectByURL(this.documents.businessDocument)
            : null;
          this.personalDocument = this.documents.personalDocument
            ? this.filterObjectByURL(this.documents.personalDocument)
            : null;
          this.propertyDocument = this.documents.propertyDocument
            ? this.filterObjectByURL(this.documents.propertyDocument)
            : null;
        }

        const attomDocdata = await firebaseDB
          .firestore()
          .collection("loans")
          .doc(this.$props.id)
          .collection("attomData")
          .doc(this.losObject.latestAttomDataId)
          .get();
        this.attomDataObject = { ...attomDocdata.data() };

        if (this.losObject && this.losObject.latestSalesComparablesParamId) {
          const salesComparablesParamData = await firebaseDB
            .firestore()
            .collection("loans")
            .doc(this.$props.id)
            .collection("salesComparablesParameters")
            .doc(this.losObject.latestSalesComparablesParamId)
            .get();
          let salesComparablesParamDataObject = {
            ...salesComparablesParamData.data(),
          };

          this.salesComparablesParam =
            salesComparablesParamDataObject.params[
              salesComparablesParamDataObject.params.length - 1
            ];

          this.mile = this.salesComparablesParam["mile"];
          this.bedroomsRange = this.salesComparablesParam["bedroomsRange"];
          this.bathroomRange = this.salesComparablesParam["bathroomRange"];
          this.sqFeetRange = this.salesComparablesParam["sqFeetRange"];
          console.log("this.salesComparablesParam", this.salesComparablesParam);
        }
        const loanApprovalData = await firebaseDB
          .firestore()
          .collection("loans")
          .doc(this.$props.id)
          .collection("loanApproval")
          .doc(this.losObject.loanPreApprovalId)
          .get();
        this.loanPreApprovalData = { ...loanApprovalData.data() };
        const runsDocData = await firebaseDB
          .firestore()
          .collection("loans")
          .doc(this.$props.id)
          .collection("runs")
          .orderBy("createdAt", "desc")
          .limit(1)
          .get();

        if (runsDocData.docs.length > 0) {
          this.runsData = runsDocData.docs[0].data();
        }

        this.viabilityData =
          this.runsData && this.runsData.viability
            ? this.runsData.viability
            : null;

        console.log("losObject", this.losObject);
        console.log("losLogObject", this.losLogObject);
        console.log("attom data object--", this.attomDataObject);
        console.log("loanPreApprovalData ---", this.loanPreApprovalData);
        if (this.attomDataObject.neighborhoodCommunityData) {
          this.communitydata = JSON.stringify(
            this.attomDataObject.neighborhoodCommunityData
          );
        }
        if (this.attomDataObject.salesTrendData) {
          this.salestrenddata = JSON.stringify(
            this.attomDataObject.salesTrendData
          );
        }
        this.losObject.createdAt = this.losObject.createdAt.toDate();

        const aiAnalyzeData = await firebaseDB
          .firestore()
          .collection("loans")
          .doc(this.$props.id)
          .collection("aiAnalysis")
          .doc(this.losObject.aiAnalysisId)
          .get();
        this.aiAnalyzeData = { ...aiAnalyzeData.data() };
        console.log("this.aiAnalyzeData----", this.aiAnalyzeData);
        // await this.mapfields();
        this.objectsToStrings();
        this.calculatorFields();
        this.salesCompsProperties();
        this.dealDataProperties();
        this.neighborhoodHEValue = this.saleObj[0].AVMPSF * this.propertySize;

        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.log("Error in fetching los detail:\n" + error);
      }
    },

    updatePropertyClosingCost: function (newValue) {
      this.myValue = newValue;
    },

    formatStrCurrency: function (str) {
      str = str.replace(/[,$]/g, "");
      let num = parseFloat(str);

      let formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
      });

      let val = formatter.format(num);
      return val;
    },

    formatCurrency: function (num) {
      let formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
      });

      let val = formatter.format(num);
      return val;
    },

    format$Currency: function (num) {
      if (num == null || typeof num === "undefined") {
        return "";
      }
      const roundedValue = Math.ceil(num);

      return roundedValue.toLocaleString("en-US");
    },

    formatKCurrency: function (num) {
      num = parseFloat(num);
      if (num >= 1000) {
        return "$" + (num / 1000).toFixed(0) + "k";
      } else {
        return "$" + num.toFixed(0);
      }
    },

    formatCurrencyProcessing: function (num) {
      let formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      });

      let val = formatter.format(num);
      return val;
    },

    async mapfields() {
      var result = Object.entries(this.losObject);

      const sortedLosDetailsFieldMap = this.losDetailsFieldMap.sort(
        (a, b) => a.order - b.order
      );

      await sortedLosDetailsFieldMap.map((losmapobj) => {
        result.forEach((el) => {
          if (el[0] === losmapobj.field) {
            el[0] = losmapobj.value;
          }
        });
      });

      const orderedResult = {};
      sortedLosDetailsFieldMap.forEach((losmapobj) => {
        const key = losmapobj.value;
        const entry = result.find(([e1]) => e1 === key) || [null, null];
        const value = entry[1];
        orderedResult[key] = value;
      });

      this.mappedLosObj = orderedResult;
    },
    animateProgressBar() {
      const updateProgress = () => {
        if (!this.progressComplete) {
          this.progress += 5;
        }
        if (this.progressComplete) {
          this.progress = 100;
          this.progressComplete = false;
          clearInterval(progressInterval);
        }
        if (this.progress >= 100) {
          this.progress = 100;
          this.progressComplete = false;
          clearInterval(progressInterval);
        }
      };
      const progressInterval = setInterval(updateProgress, 500);
    },
    async generateLoanReport() {
      this.loadingBar = true;
      this.progress = 0;
      this.animateProgressBar();
      let pdfData = {
        id: this.id,
        report: "loan",
      };

      const url =
        location.hostname === "localhost"
          ? `http://localhost:5000`
          : `https://hermosa-server-dot-crebrid-os.uc.r.appspot.com`;

      this.disableDownload = true;
      axios
        .post(`${url}/api/v1/pdf/download-pdf`, {
          losObject: pdfData,
        })
        .then((response) => {
          this.progressComplete = true;
          const pdfUrl = response.data.URL;
          this.disableDownload = true;

          const a = document.createElement("a");
          a.href = pdfUrl;
          a.download = `LoanReport`;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          this.disableDownload = false;
          this.loadingBar = false;
          this.progressComplete = false;
        })
        .catch((err) => {
          this.alertMessage = "Something went wrong, please try again later!";
          this.alertType = "error";
          this.alertState = true;
          console.log("Error: ", err);
          this.loadingBar = false;
        });
    },

    async generateReport() {
      this.loadingBar = true;
      this.progress = 0;
      this.animateProgressBar();

      let pdfData = {
        id: this.id,
      };

      const url =
        location.hostname === "localhost"
          ? `http://localhost:5000`
          : `https://hermosa-server-dot-crebrid-os.uc.r.appspot.com`;
      this.disableDownload = true;
      axios
        .post(`${url}/api/v1/pdf/download-pdf`, {
          losObject: pdfData,
        })
        .then((response) => {
          const pdfUrl = response.data.URL;
          this.disableDownload = true;

          const a = document.createElement("a");
          a.href = pdfUrl;
          a.download = `PreApprovalReport`;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          this.disableDownload = false;
          this.loadingBar = false;
          this.progressComplete = false;
        })
        .catch((err) => {
          this.alertMessage = "Something went wrong, please try again later!";
          this.alertType = "error";
          this.alertState = true;
          console.log("Error: ", err);
          this.loading = false;
        });
    },

    objectsToStrings() {
      this.attomAvmDetail = JSON.stringify(this.attomDataObject.attomAvmDetail);
      this.subjectPropertyAVMDetail = JSON.stringify(
        this.attomDataObject.subjectPropertyAvmDetail
      );
      this.salesComparables = JSON.stringify(
        this.attomDataObject.salesComparables
      );

      this.closestPropertiesFullData = JSON.stringify(
        this.attomDataObject.closestPropertiesFullData
      );
      this.closestPropertiesMinData = JSON.stringify(
        this.attomDataObject.closestPropertiesMinData
      );

      for (let i = 1; i < 11; i++) {
        if (this.attomDataObject[`compProp${i}Avm`]) {
          let com = `compProp${i}Avm`;
          this[com] = JSON.stringify(this.attomDataObject[`compProp${i}Avm`]);
        } else {
          break;
        }
      }

      if (this.attomDataObject.attomAvmDetail.property) {
        // this.avmSnapShot = JSON.stringify(this.attomDataObject.avmSnapShot);
        // this.avmSnapShotDetails = this.attomDataObject.avmSnapShot.property;
        this.avmDeatils = this.attomDataObject.attomAvmDetail.property;
        // this.avmAssessmentSnapShot = this.attomDataObject.avmAssessmentSnapShot;
        // this.avmAssessmentSnapShotString = JSON.stringify(
        //   this.avmAssessmentSnapShot
        // );
      }
    },

    calculatorFields() {
      this.purchasePrice = this.losObject.purchasePrice.replace(/[$,]/g, "");
      this.rehabBudget = this.losObject.rehabBudget.replace(/[$,]/g, "");
      this.ARV = this.losObject.arvValue;
      if (this.attomDataObject.attomAvmDetail) {
        this.avmProperty = this.attomDataObject.attomAvmDetail.property;
      }
      this.loanToCost =
        this.losObject && this.losObject.LTC_percentage
          ? this.losObject.LTC_percentage
          : 0;
      this.loanToValue =
        this.losObject && this.losObject.LTV_percentage
          ? this.losObject.LTV_percentage
          : 0;
      this.predicted_days_on_market =
        this.losObject &&
        this.losObject?.prediction_mls_features?.predicted_days_on_market
          ? this.losObject.prediction_mls_features.predicted_days_on_market
          : 0;
    },
    dealDataProperties() {
      this.dealData =
        this.runsData && this.runsData.dealData ? this.runsData.dealData : null;
      this.drawFees =
        this.dealData && this.dealData.drawFees ? this.dealData.drawFees : 0;
      this.totalAcquisitionCost =
        this.dealData && this.dealData.totalAcquisitionCost
          ? this.dealData.totalAcquisitionCost
          : 0;
      this.downPayment =
        this.dealData && this.dealData.downPayment
          ? this.dealData.downPayment
          : 0;
      this.interestAmount =
        this.dealData && this.dealData.interestTotalAmount
          ? Math.round(this.dealData.interestTotalAmount)
          : 0;
      this.interestMonthlyAmount =
        this.dealData && this.dealData.interestMonthlyAmount
          ? Math.round(this.dealData.interestMonthlyAmount)
          : 0;
      this.loanPointsAmount =
        this.dealData && this.dealData.loanPointsAmount
          ? Math.round(this.dealData.loanPointsAmount)
          : 0;
      this.bpoAppraisalValue =
        this.dealData && this.dealData.bpoAppraisalValue
          ? this.dealData.bpoAppraisalValue
          : 0;
      this.realtorAmount =
        this.dealData && this.dealData.realtorAmount
          ? this.dealData.realtorAmount
          : 0;
      this.propertyTaxes =
        this.dealData && this.dealData.propertyTaxes
          ? Math.round(this.dealData.propertyTaxes)
          : 0;
      this.propertyInsurance =
        this.dealData && this.dealData.propertyInsurance
          ? Math.round(this.dealData.propertyInsurance)
          : 0;
      this.titleInsuranceAmount =
        this.dealData && this.dealData.titleInsuranceAmount
          ? Math.round(this.dealData.titleInsuranceAmount)
          : 0;
      this.governmentFee =
        this.dealData && this.dealData.governmentFee
          ? this.dealData.governmentFee
          : 0;
      this.warehouseInvestorFee =
        this.dealData && this.dealData.warehouseInvestorFee
          ? this.dealData.warehouseInvestorFee
          : 0;
      this.utilities =
        this.dealData && this.dealData.utilities ? this.dealData.utilities : 0;
      this.acquisitionCostPsf =
        this.dealData && this.dealData.acquisitionCostPsf
          ? Math.round(this.dealData.acquisitionCostPsf)
          : 0;
      this.arvPsfValue =
        this.dealData && this.dealData.arvPsfValue
          ? Math.round(this.dealData.arvPsfValue)
          : 0;
      this.loanTermMonths =
        this.dealData && this.dealData.loanTermDuration
          ? this.dealData.loanTermDuration
          : 0;
      this.interestRate =
        this.dealData && this.dealData.interestRate
          ? this.dealData.interestRate * 100
          : 0;
      this.loanPointsPercent =
        this.dealData && this.dealData.loanPointsPercent
          ? this.dealData.loanPointsPercent
          : 0;
      this.realtorRate =
        this.dealData && this.dealData.realtorRate
          ? this.dealData.realtorRate
          : 0;
      this.taxRate =
        this.dealData && this.dealData.taxRate ? this.dealData.taxRate : 0;
      this.insuranceRate =
        this.dealData && this.dealData.insuranceRate
          ? this.dealData.insuranceRate
          : 0;
      this.titleInsurancePercent =
        this.dealData && this.dealData.titleInsurancePercent
          ? this.dealData.titleInsurancePercent
          : 0;
      this.profitPercentage =
        this.dealData && this.dealData.profitPercentage
          ? this.dealData.profitPercentage
          : 0;
      this.profitDollarAmount =
        this.dealData && this.dealData.profitDollarAmount
          ? this.dealData.profitDollarAmount
          : 0;
      this.loanAmount =
        this.dealData && this.dealData.loanAmount
          ? Math.round(this.dealData.loanAmount)
          : 0;
      this.totalCostBasis =
        this.dealData && this.dealData.totalCostBasis
          ? this.dealData.totalCostBasis
          : 0;
    },
    salesCompsProperties() {
      this.keystoneData =
        this.runsData && this.runsData.keyStoneResult
          ? this.runsData.keyStoneResult
          : null;
      this.totalBedrooms =
        this.keystoneData && this.keystoneData.totalBedrooms
          ? this.keystoneData.totalBedrooms
          : 0;
      this.totalBathrooms =
        this.keystoneData && this.keystoneData.totalBathrooms
          ? this.keystoneData.totalBathrooms
          : 0;
      this.propertySize =
        this.keystoneData && this.keystoneData.propertySize
          ? this.keystoneData.propertySize
          : 0;
      this.Lot =
        this.keystoneData && this.keystoneData.Lot ? this.keystoneData.Lot : 0;
      this.avmAmount =
        this.keystoneData && this.keystoneData.avmAmount
          ? this.keystoneData.avmAmount
          : 0;
      this.avmAmountLow =
        this.keystoneData && this.keystoneData.avmAmountLow
          ? this.keystoneData.avmAmountLow
          : 0;
      this.avmAmountHigh =
        this.keystoneData && this.keystoneData.avmAmountHigh
          ? this.keystoneData.avmAmountHigh
          : 0;
      this.avgCompAvmPsf =
        this.keystoneData && this.keystoneData.avgCompAvmPsf
          ? this.keystoneData.avgCompAvmPsf
          : 0;
      this.avgCompAssessment =
        this.keystoneData && this.keystoneData.avgCompAssessment
          ? this.keystoneData.avgCompAssessment
          : 0;
      this.avgCompAssessmentPsf =
        this.keystoneData && this.keystoneData.avgCompAssessmentPsf
          ? this.keystoneData.avgCompAssessmentPsf
          : 0;
      this.assessmentValue =
        this.keystoneData && this.keystoneData.assessmentValue
          ? this.keystoneData.assessmentValue
          : 0;
      this.assessmentValuePSF =
        this.keystoneData && this.keystoneData.assessmentValuePSF
          ? this.keystoneData.assessmentValuePSF
          : 0;
      this.avgCompAvm =
        this.keystoneData && this.keystoneData.avgCompAvm
          ? this.keystoneData.avgCompAvm
          : 0;
      this.avgCompSqft =
        this.keystoneData && this.keystoneData.avgCompSqft
          ? this.keystoneData.avgCompSqft
          : 0;
      this.avmPsfValue =
        this.keystoneData && this.keystoneData.avmPsfValue
          ? Math.round(this.keystoneData.avmPsfValue)
          : 0;
      this.minComAvmpsf =
        this.keystoneData && this.keystoneData.minComAvmpsf
          ? this.keystoneData.minComAvmpsf
          : 0;
      this.maxComAvmpsf =
        this.keystoneData && this.keystoneData.maxComAvmpsf
          ? this.keystoneData.maxComAvmpsf
          : 0;
      this.LE =
        this.keystoneData && this.keystoneData.LE ? this.keystoneData.LE : 0;
      this.keystoneV3median =
        this.keystoneData && this.keystoneData.keystoneV3median
          ? this.keystoneData.keystoneV3median
          : 0;
      this.keystoneV3highV2 =
        this.keystoneData && this.keystoneData.keystoneV3highV2
          ? this.keystoneData.keystoneV3highV2
          : 0;
      this.keystoneV3lowV2 =
        this.keystoneData && this.keystoneData.keystoneV3lowV2
          ? this.keystoneData.keystoneV3lowV2
          : 0;
      this.keystoneV3medianV2 =
        this.keystoneData && this.keystoneData.keystoneV3medianV2
          ? this.keystoneData.keystoneV3medianV2
          : 0;
      this.salesComparablesCount =
        this.keystoneData && this.keystoneData.saleObj
          ? this.keystoneData.saleObj.length - 1
          : 0;
      this.saleObj =
        this.keystoneData && this.keystoneData.saleObj
          ? this.keystoneData.saleObj
          : 0;
      this.subjectProperty =
        this.keystoneData && this.keystoneData.saleObj
          ? this.keystoneData.saleObj.find(
              (item) => item.isSubjectProperty === true
            )
          : "";
      this.subjectPropertyLat = this.subjectProperty.lat;
      this.subjectPropertyLng = Number(this.subjectProperty.long);
      // this.avmSnapShotMap(
      //   this.avmSnapShotDetails,
      //   this.subjectProperty,
      //   this.avmAssessmentSnapShot
      // );
      console.log("subject property data---->", this.subjectProperty);
      this.streetViewMap(this.subjectProperty);
    },

    clicked() {
      this.editMode = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.full-height {
  min-height: 100vh;
}
.v-text-field .v-label {
  overflow: visible !important;
  top: 1% !important;
}

.label {
  color: #c7c7c7 !important;
}

.item {
  cursor: pointer;
}
.fa {
  margin: 3px;
  font-size: 1rem;
}

.v-tab {
  text-transform: none !important;
}

.d-flex {
  display: flex;
  justify-content: space-between;

  .round {
    width: 20px;
    height: 20px;

    border-radius: 50%;
  }
}
.tab-nav-btn {
  background: #212121;
  width: 100%;
  max-width: 300px;
  padding: 0 16px;
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;

  a {
    text-decoration: none;
    color: #69f0ae;
  }
}

#mapdiv {
  min-height: 500px;
  width: 100%;
}

#avm_ss_map_div {
  min-height: 500px;
  width: 100%;
}

#avm_details_map_div {
  min-height: 500px;
  width: 100%;
}

#pano {
  min-height: 500px;
  width: 100%;
}
</style>
