<template>
  <div id="app">
    <v-app v-if="$auth.isAuthenticated">
      <v-app-bar-nav-icon
        class="hidden-md-and-up main-drawer"
        fixed
        right
        top
        variant="text"
        @click.stop="drawer = !drawer"
        @click="addClass"
      ></v-app-bar-nav-icon>
      <router-view></router-view>
      <v-navigation-drawer
        app
        clipped
        permanent
        :class="{ isHidden: isHiddenData }"
        mini-variant
        expand-on-hover
        :color="color"
        dark
      >
        <v-list dense nav class="py-0">
          <v-list-item two-line :class="miniVariant && 'px-0'">
            <v-list-item-avatar class="mb-0 mt-1">
              <img src="@/assets/crebrid-icon.png" class="pa-1" />
            </v-list-item-avatar>

            <v-list-item-content class="mt-2">
              <v-list-item-title class="v-list-item__app-title"
                >Crebrid <b>OS</b></v-list-item-title
              >
              <v-list-item-subtitle>{{
                $auth.user.email
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-divider class="my-2"></v-divider>

          <div v-for="(link, i) in links" :key="i">
            <v-list-item
              v-if="!link.subLinks"
              :to="link.to"
              avatar
              class="v-list-item my-1"
            >
              <v-list-item-icon>
                <v-icon>{{ link.icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-title v-text="link.text" />
            </v-list-item>

            <v-list-group
              v-else
              :key="link.text"
              no-action
              :prepend-icon="link.icon"
              :value="false"
            >
              <template v-slot:activator>
                <v-list-item-title>{{ link.text }}</v-list-item-title>
              </template>

              <v-list-item
                v-for="sublink in link.subLinks"
                :to="sublink.to"
                :key="sublink.text"
              >
                <v-list-item-title>{{ sublink.text }}</v-list-item-title>
              </v-list-item>
            </v-list-group>
          </div>
        </v-list>

        <template v-slot:append>
          <v-list dense nav class="py-0">
            <a
              href="https://crebrid.notion.site/Crebrid-OS-Wiki-0dcef4e6349a4a02908c3b115de9c157"
              target="_blank"
            >
              <v-list-item>
                <v-list-item-icon>
                  <v-icon>mdi-help-network</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>Documentation</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </a>
            <v-list-item to="/users">
              <v-list-item-icon>
                <v-icon>mdi-security-network</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>Administration</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item to="/settings">
              <v-list-item-icon>
                <v-icon>mdi-cog</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>Settings</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="logout">
              <v-list-item-icon>
                <v-icon>mdi-power</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>Log out</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </template>
      </v-navigation-drawer>

      <!-- <v-navigation-drawer
        v-model="drawer"
        :color="color"
        :expand-on-hover="expandOnHover"
        :mini-variant="miniVariant"
        :right="right"
        :fixed="fixed"
        :permanent="$vuetify.breakpoint.mdAndUp"
        dark
      >
        <v-list dense nav class="py-0">
          <v-list-item two-line :class="miniVariant && 'px-0'">
            <v-list-item-avatar class="mb-0 mt-1">
              <img src="@/assets/crebrid-icon.png" class="pa-1" />
            </v-list-item-avatar>

            <v-list-item-content class="mt-2">
              <v-list-item-title class="v-list-item__app-title"
                >Crebrid <b>OS</b></v-list-item-title
              >
              <v-list-item-subtitle>{{
                $auth.user.email
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-divider class="my-2"></v-divider>

          <v-list-item v-for="item in items" :key="item.title" :to="item.link">
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <template v-slot:append>
          <v-list dense nav class="py-0">
            <a
              href="https://www.notion.so/nalu/Crebrid-OS-Wiki-66ad2b73f1114b279c6eb6d21c43da19"
              target="_blank"
            >
              <v-list-item to="/">
                <v-list-item-icon>
                  <v-icon>mdi-help-network</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>Documentation</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </a>
            <v-list-item to="/users">
              <v-list-item-icon>
                <v-icon>mdi-security-network</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>Administration</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item to="/settings">
              <v-list-item-icon>
                <v-icon>mdi-cog</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>Settings</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="logout">
              <v-list-item-icon>
                <v-icon>mdi-power</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>Log out</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </template>
      </v-navigation-drawer> -->

      <v-footer class="d-flex justify-center mt-12 py-4 nalu-footer">
        <p>
          &copy; {{ new Date().getFullYear() }} Crebrid. All Rights Reserved.
          Version 1.08.16
        </p>
      </v-footer>
    </v-app>

    <div v-else>
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      drawer: true,
      isHiddenData: true,
      links: [
        {
          to: "/home",
          icon: "mdi-home",
          text: "Home",
        },
        {
          to: "/dash",
          icon: "mdi-view-dashboard",
          text: "Dashboard",
        },
        {
          icon: "mdi-robot",
          text: "Automations",
          subLinks: [
            {
              text: "Dashboard",
              to: "/automations/dashboard",
              icon: "mdi-view-dashboard",
            },
            {
              text: "Projects",
              to: "/automations/projects",
              icon: "mdi-clipboard-text-play",
            },
            { text: "Snaps", to: "/automations/snaps", icon: "mdi-cloud-tags" },
            { text: "Jobs", to: "/automations/schedule", icon: "mdi-head-cog" },
            { text: "Timeline", to: "/automations/timeline", icon: "mdi-head-cog" },
          ],
        },
        {
          icon: "mdi-cash-fast",
          text: "LOS",
          subLinks: [
            {
              text: "Loans",
              to: "/los/loans",
              icon: "mdi-file-document-multiple",
            },
            {
              text: "Map",
              to: "/los/map",
              icon: "mdi-file-document-multiple",
            },
          ],
        },
        {
          icon: "mdi-bullhorn",
          text: "Marketing",
          subLinks: [
            {
              text: "Blog",
              to: "/marketing/blog",
              icon: "mdi-post",
            },
            {
              text: "CRM",
              to: "/marketing/crm",
              icon: "mdi-account-group",
            },
            {
              text: "Competitors",
              to: "/marketing/lenders",
              icon: "mdi-format-list-bulleted",
            },
            {
              text: "Markets",
              to: "/marketing/rank",
              icon: "mdi-format-list-numbered",
            },
          ],
        },
        {
          to: "/data",
          icon: "mdi-warehouse",
          text: "Data",
        },
      ],

      right: false,
      miniVariant: true,
      expandOnHover: true,
      background: false,
      fixed: true,
      color: "grey darken-4",
      linkColor: "grey darken-1",
    };
  },
  methods: {
    // Log the user out
    addClass() {
      this.isHiddenData = !this.isHiddenData;
      // document.getElementById("app").classList.toggle("expand");
      // document
      //   .getElementById("app")
      //   .classList.toggle("navigation-drawer--open-on-hover");
    },
    logout() {
      this.$auth.logout({
        returnTo: window.location.origin,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css?family=Kumbh+Sans&display=swap");

.v-application {
  font-family: "Kumbh Sans" !important ;
  background-color: #121212 !important;
}
.v-application .primary--text {
  color: #fff !important;
  caret-color: #fff !important;
}
.v-list-item__app-title {
  font-weight: 800 !important;
  font-size: 1rem !important;
}
.v-avatar {
  border-radius: 0 !important;
  height: auto !important;
}
a {
  text-decoration: none;
}
.nalu-footer {
  background-color: #121212 !important;

  p {
    font-size: 12px;
  }
}
.theme--dark.v-btn.v-btn--icon {
  z-index: 99;
}

#app {
  // &.expand {
  //   aside {
  //     width: 256px !important;
  //   }
  // }
  aside {
    @media (max-width: 991px) {
      transform: translateX(-100%);
      &.v-navigation-drawer--open {
        transform: translateX(0);
      }
    }
  }
  .isHidden {
    @media (max-width: 991px) {
      display: none;
    }
  }
}
</style>
