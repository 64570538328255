<template>
  <div>
    <!-- Marker Type Selection -->
    <v-row>
      <v-col sm="12" class="">
        <div class="text-subtitle-1 white--text pb-5">Map</div>
        <v-card class="mx-auto" color="grey darken-3">
          <!-- <v-card-title class="text-h5 mb-1 font-weight-bold">Map</v-card-title> -->
          <v-card-text class="d-flex">
            <v-select
              v-model="selectedMarkerType"
              :items="markerType"
              label="Map Data"
              multiple
              chips
              small-chips
              @change="handleMarkerTypeFilterChange"
            ></v-select>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- Map Display -->
    <div id="map1" ref="map1" style="height: 550px"></div>
    <!-- <v-sheet :elevation="5" dark class="pa-5" style="position: relative">
      <div class="text-h5 mb-1 font-weight-bold">Maps</div>
      <div id="map1" ref="map1" style="height: 400px"></div>
    </v-sheet> -->
  </div>
</template>

<script>
import axios from "axios";
import { firebaseDB } from "../auth/firebase-auth.js";
export default {
  props: {
    loan: Object,
    subjectProperty: Object,
    zoomLevel: Number,
    saleObj: Array,
    avmSnapShotDetails: Array,
    avmAssessmentSnapShot: Object,
    funded: Array,
    inProgress: Array,
    closestPropertyObj: Array,
  },
  data() {
    return {
      selectedMarkerType: [],
      defaultMarkerTypes: ["subjectProperty"],
      markerType: [
        { value: "funded", text: "Funded" },
        { value: "inProgress", text: "In Progress" },
        { value: "subjectProperty", text: "Subject Property" },
        { value: "salesComparables", text: "Sales Comparables" },
        { value: "AVMSnapshot", text: "AVM Snapshot" },
        { value: "AVMClosestProperty", text: "AVM Closest Properties" },
      ],
      markers: {
        funded: [],
        inProgress: [],
        subjectProperty: [],
        salesComparables: [],
        AVMSnapshot: [],
        AVMClosestProperty: [],
      },
      map: null,
      infoWindowContents: {}, // Will be populated dynamically
    };
  },
  mounted() {
    this.selectedMarkerType = ["subjectProperty", "AVMClosestProperty"]; // By default these should be selected
    this.loadMap();
  },
  methods: {
    handleMarkerTypeFilterChange() {
      // Function to handle dropdown change
      // Update markers on the map based on selectedMarkerType
      // Clear existing markers from the map
      this.clearMarkers();
      // Add markers for selectedMarkerType or default marker types if no type is selected
      if (this.selectedMarkerType.length === 0) {
        // Add multiple default marker types
        this.defaultMarkerTypes.forEach((type) => {
          this.addMarkers(type);
        });
      }
      // Add markers for selectedMarkerType
      this.selectedMarkerType.forEach((type) => {
        this.addMarkers(type);
      });
    },

    clearMarkers() {
      Object.values(this.markers).forEach((markerArray) => {
        markerArray.forEach((marker) => {
          marker.setMap(null);
        });
        markerArray.length = 0;
      });
    },

    async addMarkers(type) {
      const markerData = await this.fetchMarkerData(type);
      markerData.forEach((location) => {
        const marker = new google.maps.Marker({
          position: { lat: location.lat, lng: location.lng },
          map: this.map,
          icon: this.markerIcons[type],
          animation: this.markerAnimations[type],
        });

        const infoWindowContent = this.getInfoWindowContent(type, location); // Dynamic info window content
        const infoWindow = new google.maps.InfoWindow({
          content: infoWindowContent,
        });

        marker.addListener("click", () => {
          infoWindow.open(this.map, marker);
        });

        this.markers[type].push(marker);
      });
    },

    async fetchMarkerData(type) {
      // Simulated fetching of marker data based on type
      // Replace this with actual asynchronous fetching logic
      // Return a Promise with marker data
      return new Promise((resolve) => {
        setTimeout(async () => {
          let data;
          // Simulated data for each marker type
          if (type === "funded") {
            data = await this.fundedMap(this.funded);
          } else if (type === "inProgress") {
            data = await this.inProgressMap(this.inProgress);
          } else if (type === "subjectProperty") {
            data = await this.subjectPropertyMap(this.loan);
          } else if (type === "salesComparables") {
            data = await this.salesPropertyMap(this.saleObj);
          } else if (type === "AVMSnapshot") {
            data = await this.avmSnapShotMap(
              this.avmSnapShotDetails,
              this.avmAssessmentSnapShot
            );
          } else if (type === "AVMClosestProperty") {
            data = await this.closestPropertyMap(this.closestPropertyObj);
          }
          resolve(data);
        }, 0);
      });
    },

    async salesPropertyMap(saleObj) {
      saleObj.map((location) => {
        location.lat = Number(location.lat);
        location.lng = Number(location.long);
      });
      return saleObj;
    },
    async closestPropertyMap(closestPropertiesFullData) {
      closestPropertiesFullData.map((property) => {
        property.lat = Number(property.property.location.latitude);
        property.lng = Number(property.property.location.longitude);
        property.Address = property.property.address.oneLine;
        property.AVMValue = property.property.avm.amount.value;
        property.assessmentValue =
          property.property.assessment.assessed.assdttlvalue;
      });
      return closestPropertiesFullData;
    },
    async subjectPropertyMap(subjectProperty) {
      let location = {};
      if (subjectProperty.latitude && subjectProperty.longitude) {
        location.lat = Number(subjectProperty.latitude);
        location.lng = Number(subjectProperty.longitude);
      } else {
        if (
          subjectProperty.prediction_mls_features.Latitude &&
          subjectProperty.prediction_mls_features.Longitude
        ) {
          location.lat = subjectProperty.prediction_mls_features.Latitude;
          location.lng = subjectProperty.prediction_mls_features.Longitude;
        }
      }

      if (!location || !location.lat || !location.lng) {
        console.error("Error fetching location data");
        return null;
      }
      return [location];
    },
    async getLoanAmount(collectionid, mortgageLoanId) {
      try {
        let loanAmount = 0;
        let mortgageLoanDocument = await firebaseDB
          .firestore()
          .collection("mortgageLoans")
          .doc(collectionid)
          .get();

        if (mortgageLoanDocument.exists) {
          const mortgageLoansDocData = await firebaseDB
            .firestore()
            .collection("mortgageLoans")
            .doc(collectionid)
            .collection("mortgageAutomatorLoan")
            .doc(mortgageLoanId)
            .get();
          let mortgageLoans = mortgageLoansDocData.data();
          if (
            mortgageLoans &&
            mortgageLoans.mortgage &&
            mortgageLoans.mortgage.amount
          ) {
            loanAmount = mortgageLoans.mortgage.amount;
            await firebaseDB
              .firestore()
              .collection("mortgageLoans")
              .doc(collectionid)
              .update({
                amount: loanAmount,
              });
            return loanAmount;
          }
        }
        return loanAmount;
      } catch (error) {
        console.error("Error fetching mortgage data:", error);
      }
    },
    async fundedMap(fundedloans) {
      let data = [];
      const markerPromises = fundedloans.map(async (zipCode) => {
        try {
          let location = {};
          if (
            zipCode.property.address &&
            zipCode.property.address.latitude !== undefined &&
            zipCode.property.address.longitude !== undefined
          ) {
            location.latitude = zipCode.property.address.latitude;
            location.longitude = zipCode.property.address.longitude;
          } else {
            location = await this.getLatLongFromZipcode(
              zipCode.property.address.zip,
              zipCode.property.address.street,
              zipCode.property.address.country
            );
            await firebaseDB
              .firestore()
              .collection("mortgageLoans")
              .doc(zipCode.id)
              .update({
                "property.address.latitude": location.latitude,
                "property.address.longitude": location.longitude,
              });
          }
          if (!location || !location.latitude || !location.longitude) {
            console.error("Error fetching location data");
            return null; // Skip this marker
          }

          const { latitude, longitude } = location;
          let loanAmount = zipCode.amount;
          if (!loanAmount) {
            const mortgageLoanId = zipCode.mortgageLoanId;
            loanAmount = await this.getLoanAmount(zipCode.id, mortgageLoanId);
          }
          const address = `${zipCode.property.address.street}, ${zipCode.property.address.city}, ${zipCode.property.address.country}`;
          const loanTitle = zipCode.loan_title;
          const latLng = {
            lat: latitude,
            lng: longitude,
            amount: loanAmount,
            address: address,
            loanTitle: loanTitle,
          };
          data.push(latLng);
        } catch (error) {
          console.error("Error processing marker:", error);
        }
      });
      await Promise.all(markerPromises);
      console.log(data);
      return data;
    },
    async inProgressMap(inProgressLoan) {
      let data = [];
      const markerPromises = inProgressLoan.map(async (zipCode) => {
        let location = {};
        console.log(zipCode);
        if (
          zipCode.property.address &&
          zipCode.property.address.latitude !== undefined &&
          zipCode.property.address.longitude !== undefined
        ) {
          location.latitude = zipCode.property.address.latitude;
          location.longitude = zipCode.property.address.longitude;
        } else {
          location = await this.getLatLongFromZipcode(
            zipCode.property.address.zip,
            zipCode.property.address.street,
            zipCode.property.address.country
          );
          await firebaseDB
            .firestore()
            .collection("mortgageLoans")
            .doc(zipCode.id)
            .update({
              "property.address.latitude": location.latitude,
              "property.address.longitude": location.longitude,
            });
        }

        if (!location || !location.latitude || !location.longitude) {
          console.error("Error fetching location data");
          return null; // Skip this marker
        }

        const { latitude, longitude } = location;
        let loanAmount = zipCode.amount;
        if (!loanAmount) {
          const mortgageLoanId = zipCode.mortgageLoanId;
          loanAmount = await this.getLoanAmount(zipCode.id, mortgageLoanId);
        }
        const address = `${zipCode.property.address.street}, ${zipCode.property.address.city}, ${zipCode.property.address.country}`;
        const loanTitle = zipCode.loan_title;
        const latLng = {
          lat: latitude,
          lng: longitude,
          amount: loanAmount,
          address: address,
          loanTitle: loanTitle,
        };
        data.push(latLng);
      });
      await Promise.all(markerPromises);
      console.log(data);
      return data;
    },
    async getLatLongFromZipcode(zipcode, street, country) {
      const apiKey = "AIzaSyBkvot_wof6iT87tGnaYMA9ZFIFRvZOK5o";
      let url;
      if (
        street == null &&
        country == null &&
        street == undefined &&
        country == undefined
      ) {
        url = `https://maps.googleapis.com/maps/api/geocode/json?components=postal_code:${zipcode}&key=${apiKey}`;
      } else if (street == null || street === undefined) {
        url = `https://maps.googleapis.com/maps/api/geocode/json?components=postal_code:${zipcode}|country:${country}&key=${apiKey}`;
      } else if (country === null || country === undefined) {
        url = `https://maps.googleapis.com/maps/api/geocode/json?components=postal_code:${zipcode}|locality:${street}&key=${apiKey}`;
      } else {
        url = `https://maps.googleapis.com/maps/api/geocode/json?components=postal_code:${zipcode}|locality:${street}|country:${country}&key=${apiKey}`;
      }

      try {
        const response = await axios.get(url);
        const data = response.data;
        if (data.status === "OK" && data.results.length > 0) {
          const result = data.results[0];
          const lat = result.geometry.location.lat;
          const lng = result.geometry.location.lng;
          return { latitude: lat, longitude: lng };
        } else {
          return { latitude: "", longitude: "" };
        }
      } catch (error) {
        console.error("Error:", error.message);
        return null;
      }
    },
    async avmSnapShotMap(avmSnapShotData, avmAssessmentData) {
      let avmDataArray = [];
      for (let i = 0; i < avmSnapShotData.length; i++) {
        var avmData = {};
        avmData.lat = Number(avmSnapShotData[i].location.latitude);
        avmData.lng = Number(avmSnapShotData[i].location.longitude);
        avmData.Address = avmSnapShotData[i].address.oneLine;
        avmData.AVMValue = avmSnapShotData[i].avm.amount.value;

        let matchingProperty = avmAssessmentData.property.find((property) => {
          return (
            property.address.oneLine === avmSnapShotData[i].address.oneLine
          );
        });

        if (matchingProperty) {
          avmData.assessmentValue =
            matchingProperty.assessment.assessed.assdttlvalue;
        }
        avmDataArray.push(avmData);
      }
      console.log(avmDataArray);
      return avmDataArray;
    },

    getInfoWindowContent(type, location) {
      let content = "";
      // Generate dynamic content based on marker type
      if (type === "funded") {
        content = `
          <div id="content">
              <div id="bodyContent">
                  </br>
                  <p style='color: black;'>
                      <b>Loan Title:</b> ${location?.loanTitle}
                  </p>
                  <p style='color: black;'>
                      <b>Address:</b> ${location?.address}
                  </p>
                  <p style='color: black;'>
                      <b>Loan Amount:</b> $${Number(
                        location?.amount
                      ).toLocaleString("en-US")}
                  </p>
              </div>
          </div>`;
      } else if (type === "inProgress") {
        content = `
          <div id="content">
              <div id="bodyContent">
                  </br>
                  <p style='color: black;'>
                      <b>Loan Title:</b> ${location?.loanTitle}
                  </p>
                  <p style='color: black;'>
                      <b>Address:</b> ${location?.address}
                  </p>
                  <p style='color: black;'>
                      <b>Loan Amount:</b> $${Number(
                        location?.amount
                      ).toLocaleString("en-US")}
                  </p>
              </div>
          </div>`;
      } else if (type === "subjectProperty") {
        content = `
        <div id="content">
          <div id="bodyContent">
            </br>
            <p style='color: black;'>
              <b>Address:</b> ${
                this.subjectProperty.Address
                  ? this.subjectProperty.Address
                  : null
              } </br>
                  <b>AVM: </b>$${parseInt(
                    this.subjectProperty.AVMValue
                      ? this.subjectProperty.AVMValue
                      : 0
                  ).toLocaleString("en-US")}</br>
                  <b>Assessment Value: </b>$${parseInt(
                    this.subjectProperty.assessmentValue
                      ? this.subjectProperty.assessmentValue
                      : 0
                  ).toLocaleString("en-US")}
            </p>
            </div>
            </div>
            `;
      } else if (type === "salesComparables") {
        content = `
            <div id="content">
            <div id="bodyContent">
            </br>
            <p style='color: black;'>
              <b>Address:</b> ${location?.Address} </br>
              <b>AVM: </b>$${parseInt(location?.AVMValue).toLocaleString(
                "en-US"
              )}</br>
              <b>Assessment Value: </b>$${parseInt(
                location?.assessmentValue
              ).toLocaleString("en-US")}
            </p>
            </div>
          </div>
            `;
      } else if (type === "AVMSnapshot" || type === "AVMClosestProperty") {
        content = `
          <div id="content">
          <div id="bodyContent">
          </br>
          <p style='color: black;'>
            <b>Address:</b> ${location?.Address} </br>
            <b>AVM: </b>$${parseInt(location?.AVMValue).toLocaleString(
              "en-US"
            )}</br>
            <b>Assessment Value: </b>$${parseInt(
              location?.assessmentValue
            ).toLocaleString("en-US")}
          </p>
          </div>
          </`;
      }
      return content;
    },

    async loadMap() {
      const subLatLong = {
        lat: Number(this.subjectProperty.lat),
        lng: Number(this.subjectProperty.long),
      };
      this.map = new google.maps.Map(this.$refs.map1, {
        center: subLatLong,
        zoom: this.zoomLevel,
        styles: [
          { elementType: "geometry", stylers: [{ color: "#242F3E" }] },
          {
            elementType: "labels.text.stroke",
            stylers: [{ color: "#242F3E" }],
          },
          { elementType: "labels.text.fill", stylers: [{ color: "#746855" }] },
          {
            featureType: "administrative.locality",
            elementType: "labels.text.fill",
            stylers: [{ color: "#D59563" }],
          },
          {
            featureType: "poi",
            elementType: "labels.text.fill",
            stylers: [{ color: "#D59563" }],
          },
          {
            featureType: "poi.park",
            elementType: "geometry",
            stylers: [{ color: "#263C3F" }],
          },
          {
            featureType: "poi.park",
            elementType: "labels.text.fill",
            stylers: [{ color: "#6B9A76" }],
          },
          {
            featureType: "road",
            elementType: "geometry",
            stylers: [{ color: "#38414E" }],
          },
          {
            featureType: "road",
            elementType: "geometry.stroke",
            stylers: [{ color: "#212A37" }],
          },
          {
            featureType: "road",
            elementType: "labels.text.fill",
            stylers: [{ color: "#9CA5B3" }],
          },
          {
            featureType: "road.highway",
            elementType: "geometry",
            stylers: [{ color: "#746855" }],
          },
          {
            featureType: "road.highway",
            elementType: "geometry.stroke",
            stylers: [{ color: "#1F2835" }],
          },
          {
            featureType: "road.highway",
            elementType: "labels.text.fill",
            stylers: [{ color: "#F3D19C" }],
          },
          {
            featureType: "transit",
            elementType: "geometry",
            stylers: [{ color: "#2F3948" }],
          },
          {
            featureType: "transit.station",
            elementType: "labels.text.fill",
            stylers: [{ color: "#D59563" }],
          },
          {
            featureType: "water",
            elementType: "geometry",
            stylers: [{ color: "#17263C" }],
          },
          {
            featureType: "water",
            elementType: "labels.text.fill",
            stylers: [{ color: "#515C6D" }],
          },
          {
            featureType: "water",
            elementType: "labels.text.stroke",
            stylers: [{ color: "#17263C" }],
          },
        ],
      });

      this.handleMarkerTypeFilterChange();
    },

    async getMapCenter() {
      // If subject property marker is in the defaultMarkerTypes array, center the map on it
      if (this.defaultMarkerTypes.includes("subjectProperty")) {
        const subjectPropertyMarkerData = await this.fetchMarkerData(
          "subjectProperty"
        );
        if (subjectPropertyMarkerData.length > 0) {
          // Get the position of the subject property marker
          return {
            lat: subjectPropertyMarkerData[0].lat,
            lng: subjectPropertyMarkerData[0].lng,
          };
        }
      } else if (this.defaultMarkerTypes.length > 0) {
        // If other default marker types are defined, center the map on the first one
        const firstDefaultMarkerData = await this.fetchMarkerData(
          this.defaultMarkerTypes[0]
        );
        if (firstDefaultMarkerData.length > 0) {
          // Get the position of the first default marker
          return {
            lat: firstDefaultMarkerData[0].lat,
            lng: firstDefaultMarkerData[0].lng,
          };
        }
      }
      console.log("ceneter==============", this.subjectProperty);
      // If no markers found, center the map at default coordinates
      return { lat: 0, lng: 0 };
    },
  },
  computed: {
    markerIcons() {
      return {
        funded: "http://maps.google.com/mapfiles/ms/icons/green-dot.png",
        inProgress: "http://maps.google.com/mapfiles/ms/icons/blue-dot.png",
        subjectProperty: "http://maps.google.com/mapfiles/ms/icons/red-dot.png",
        salesComparables:
          "http://maps.google.com/mapfiles/ms/icons/orange-dot.png",
        AVMSnapshot: "http://maps.google.com/mapfiles/ms/icons/yellow-dot.png",
        AVMClosestProperty:
          "http://maps.google.com/mapfiles/ms/icons/yellow-dot.png",
      };
    },
    markerAnimations() {
      return {
        funded: google.maps.Animation.DROP,
        inProgress: google.maps.Animation.DROP,
        subjectProperty: google.maps.Animation.BOUNCE,
        salesComparables: google.maps.Animation.DROP,
        AVMSnapshot: google.maps.Animation.DROP,
        AVMClosestProperty: google.maps.Animation.DROP,
      };
    },
  },
};
</script>
<style>
#map1 {
  height: 550px;
}

.v-select__slot {
  padding-top: 10px;
}
</style>
