<template>
  <v-overlay opacity=".95" dark>
    <div class="d-flex flex-wrap align-center justify-center">
      <img src="@/assets/crebrid-icon.png" width="80" class="mb-7" />
      <p class="white--text">
        {{ displayText }}
      </p>
    </div>
  </v-overlay>
</template>

<script>
export default {
  props: {
    loadingText: {
      type: String,
      default: null,
    },
  },
  computed: {
    displayText() {
      return this.loadingText || 'Automate data and configuration is loading...';
    }
  }
};
</script>

<style lang="scss" scoped>
p {
  width: 100%;
  text-align: center;
  color: black;
  padding: 0 24px;
}

.pulse {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  box-shadow: 0 0 0 rgba(255, 102, 200, 0.4);
  animation: pulse 1.5s infinite;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 102, 200, 0.4);
  }
  70% {
    -webkit-box-shadow: 0 0 0 30px rgba(255, 102, 200, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 102, 200, 0);
  }
}
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(255, 102, 200, 0.4);
    box-shadow: 0 0 0 0 rgba(255, 102, 200, 0.4);
  }
  70% {
    -moz-box-shadow: 0 0 0 30px rgba(255, 102, 200, 0);
    box-shadow: 0 0 0 30px rgba(255, 102, 200, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(255, 102, 200, 0);
    box-shadow: 0 0 0 0 rgba(255, 102, 200, 0);
  }
}
</style>
