<template>
  <div class="landing-page">
    <div v-if="showOverlay" class="overlay">
      <div>
        <div
          class="logo-wrapper"
          style="margin-bottom: 20px; justify-content: center"
        >
          <img src="@/assets/crebrid-logo-dark.png" class="logo" />
        </div>
        <div class="modal">
          <v-text-field
            v-model="password"
            label="Enter site password..."
            type="password"
            light
            flat
            solo-inverted
            hide-details
            clearable
            clear-icon="mdi-close-circle-outline"
            style="width: 200px"
            @keyup.enter="verifyPassword"
          ></v-text-field>

          <v-btn
            @click="verifyPassword"
            color="#70e693"
            style="font-weight: 900; margin-left: 10px; height: 48px"
            elevation="0"
            >Submit</v-btn
          >
        </div>
      </div>
    </div>
    <div class="hero-container">
      <div class="hero-wrapper">
        <v-container>
          <div>
            <div class="header-wrapper">
              <div class="logo-wrapper">
                <img src="@/assets/crebrid-logo-dark.png" class="logo" />
              </div>

              <v-spacer></v-spacer>

              <v-icon dark color="white">mdi-magnify</v-icon>

              <v-icon dark color="white" style="margin-left: 20px"
                >mdi-menu</v-icon
              >
            </div>

            <div class="hero-content">
              <v-row>
                <v-col
                  cols="12"
                  sm="12"
                  md="6"
                  lg="6"
                  style="display: flex; align-items: flex-end"
                >
                  <div class="hero-text-container">
                    <p class="credit-text">
                      Give Credit.<span class="credit-text-tm">&trade;</span>
                    </p>

                    <p class="hero-subtitle">
                      Crebrid provides short-term loans to those who buy,
                      renovate and sell real estate.
                    </p>
                  </div>
                </v-col>

                <v-col cols="12" sm="12" md="6" lg="6">
                  <div
                    class="desktop-form"
                    style="display: flex; justify-content: end"
                  >
                    <div class="application-container">
                      <div style="color: #70e693">APPLY NOW</div>
                      <div
                        class=""
                        style="
                          color: white;
                          font-size: 1.2rem;
                          padding-top: 20px;
                        "
                      >
                        Get pre-approved and we'll give you the credit you
                        deserve.
                      </div>
                      <div style="color: white; padding-top: 20px">
                        First, tell us about the property:
                      </div>
                      <v-text-field
                        label="What is the property's address?"
                        dark
                        flat
                        solo-inverted
                        hide-details
                        clearable
                        clear-icon="mdi-close-circle-outline"
                        style="width: 100%; margin-top: 20px"
                      ></v-text-field>
                      <v-text-field
                        label="What is the purchase price?"
                        dark
                        flat
                        solo-inverted
                        hide-details
                        clearable
                        clear-icon="mdi-close-circle-outline"
                        style="width: 100%; margin-top: 10px"
                      ></v-text-field>

                      <div
                        style="
                          display: flex;
                          align-items: center;
                          margin-top: 20px;
                        "
                      >
                        <div style="color: white">
                          View loan products
                          <v-icon dark small>mdi-arrow-top-right</v-icon>
                        </div>
                        <v-spacer></v-spacer>
                        <v-btn color="#70e693" elevation="0" fab small>
                          <v-icon dark>mdi-chevron-right</v-icon></v-btn
                        >
                      </div>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </div>
          </div>
        </v-container>
      </div>
    </div>

    <div class="mobile-form" style="display: flex; justify-content: end">
      <div class="application-container">
        <div style="color: #70e693">APPLY NOW</div>
        <div
          class=""
          style="color: white; font-size: 1.2rem; padding-top: 20px"
        >
          Get pre-approved and we'll give you the credit you deserve.
        </div>
        <div style="color: white; padding-top: 20px">
          First, tell us about the property:
        </div>
        <v-text-field
          label="What is the property's address?"
          dark
          flat
          solo-inverted
          hide-details
          clearable
          clear-icon="mdi-close-circle-outline"
          style="width: 100%; margin-top: 20px"
        ></v-text-field>
        <v-text-field
          label="What is the purchase price?"
          dark
          flat
          solo-inverted
          hide-details
          clearable
          clear-icon="mdi-close-circle-outline"
          style="width: 100%; margin-top: 10px"
        ></v-text-field>

        <div style="display: flex; align-items: center; margin-top: 20px">
          <div style="color: white">
            View loan products <v-icon dark small>mdi-arrow-top-right</v-icon>
          </div>
          <v-spacer></v-spacer>
          <v-btn color="#70e693" elevation="0" fab small
            ><v-icon dark>mdi-chevron-right</v-icon></v-btn
          >
        </div>
      </div>
    </div>

    <v-container>
      <div class="lp-container section">
        <v-row no-gutters>
          <v-col>
            <div>
              <p class="subtext">SPEED. EXECUTION. SERVICE.</p>
            </div>

            <div class="" style="padding-top: 30px">
              <p class="text-1">
                Are short term loans a vital component of your business?
              </p>
            </div>

            <div class="" style="padding-top: 30px">
              <p
                style="font-size: 1.5rem; font-weight: 400; line-height: 2.5rem"
              >
                We respond to speed, with speed, relying on cutting-edge, but
                <span style="font-weight: 800; text-transform: uppercase"
                  >proven technologies to expedite the application and approval
                  process.</span
                >
              </p>
            </div>

            <div class="" style="padding-top: 30px">
              <p
                style="font-size: 1.5rem; font-weight: 400; line-height: 2.5rem"
              >
                Yet speed without execution is for the uninitiated.
                <span style="font-weight: 800; text-transform: uppercase"
                  >Draw requests</span
                >
                are processed and funded<span
                  style="font-weight: 800; text-transform: uppercase"
                >
                  on time, every time.</span
                >
              </p>
            </div>

            <div class="" style="padding-top: 30px">
              <p
                style="font-size: 1.5rem; font-weight: 400; line-height: 2.5rem"
              >
                Finally,
                <span style="font-weight: 800; text-transform: uppercase"
                  >service is a priority.
                </span>
              </p>
            </div>

            <!-- <div class="hero-btn-wrapper" style="margin-top: 50px">
              <v-btn
                color="#70e693"
                style="font-weight: 900"
                >Apply for a new loan now</v-btn
              >

              <v-btn
                color="black"
                class="btn-right"
                style="font-weight: 900"
                text
                >View Products &#8599;</v-btn
              >
            </div> -->
          </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col>
            <div
              class="app-vid-wrapper"
              style="display: flex; flex-direction: column"
            >
              <!-- <img src="@/assets/os-screen.png" class="rounded-border shadow" /> -->

              <div class="image-container rounded-border shadow">
                <img
                  src="@/assets/os-screen.png"
                  alt="Zoomed Image"
                  ref="zoomedImage"
                  class="zoom-image"
                />
                <div
                  class="zoom-area"
                  @mousemove="zoomImage"
                  @mouseleave="resetZoom"
                ></div>
              </div>

              <div style="color: grey; padding-top: 20px">
                Crebrid's Origination OS
              </div>
            </div>
          </v-col>
        </v-row>
      </div>

      <div class="lp-container section lp-2">
        <v-row no-gutters>
          <v-col>
            <div>
              <p class="subtext">SMART. FAST. MOBILE.</p>
            </div>

            <div class="" style="padding-top: 30px">
              <p class="text-1">A needed step forward.</p>
            </div>

            <div class="" style="padding-top: 30px">
              <p style="font-size: 1.5rem; font-weight: 400">
                Crebrid is launching a mobile app - a revolutionary technology
                that will push short-term lending to a better place.
              </p>
            </div>

            <div class="" style="padding-top: 30px">
              <p style="font-size: 1.5rem; font-weight: 400">
                Let us quickly analyze your project and preapprove a loan -
                download the Crebrid app now.
              </p>
            </div>

            <div class="app-stores-wrapper">
              <img src="@/assets/apple-app-store.webp" />
              <img
                src="@/assets/google-play-store.webp"
                style="margin-left: 20px"
              />
            </div>
          </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col>
            <div class="app-vid-wrapper">
              <img src="@/assets/apps-img.png" />
              <!-- <video autoplay muted playsinline>
                <source :src="videoSrc" type="video/mp4" />
                Your browser does not support the video tag.
              </video> -->
            </div>
          </v-col>
        </v-row>
      </div>
    </v-container>

    <div class="c-footer">
      <v-container>
        <v-row no-gutter>
          <v-col cols="12" sm="12" md="3" lg="3">
            <div class="footer-menu-title">Technology</div>
            <div class="footer-menu-item">FAQ's</div>
            <div class="footer-menu-item">Apply Now</div>
          </v-col>

          <v-col cols="12" sm="12" md="3" lg="3">
            <div class="footer-menu-title">About</div>
            <div class="footer-menu-item">Our Story</div>
            <div class="footer-menu-item">Team</div>
            <div class="footer-menu-item">Press & Media</div>
          </v-col>

          <v-col cols="12" sm="12" md="3" lg="3">
            <div class="footer-menu-title">Support</div>
            <div class="footer-menu-item">Contact Us</div>
            <div
              class="footer-menu-item"
              @click="login"
              style="cursor: pointer"
            >
              Login to OS
            </div>
            <div class="footer-menu-item">Broker Portal</div>
          </v-col>

          <v-col cols="12" sm="12" md="3" lg="3" class="m-no"> </v-col>
        </v-row>

        <div style="padding: 40px 0 20px">
          <div class="footer-divider"></div>
        </div>

        <v-row>
          <v-col cols="12" sm="12" md="3" lg="3" class="footer-menu-item-2">
            &copy; {{ new Date().getFullYear() }} Crebrid. All Rights Reserved.
          </v-col>

          <v-col cols="12" sm="12" md="3" lg="3" class="m-no"> </v-col>

          <v-col cols="12" sm="12" md="3" lg="3">
            <v-icon dark>mdi-twitter</v-icon>
            <v-icon dark>mdi-facebook</v-icon>
            <v-icon dark>mdi-instagram</v-icon>
            <v-icon dark>mdi-youtube</v-icon>
            <v-icon dark>mdi-linkedin</v-icon>
          </v-col>

          <v-col
            cols="12"
            sm="12"
            md="3"
            lg="3"
            class="footer-menu-item-2 f-privacy-policy"
          >
            Privacy Policy
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      password: "",
      showOverlay: true,
      videoSrc: require("@/assets/app-vid-v1.mp4"),
    };
  },

  mounted() {},

  methods: {
    // Log the user in
    login() {
      //this.$auth.loginWithRedirect();
      this.$auth.loginWithPopup();
    },

    verifyPassword() {
      if (this.password === "wcl") {
        this.showOverlay = false;
      } else {
        alert("Wrong password!");
        this.password = ""; // Clear input for retry
      }
    },

    zoomImage(event) {
      const zoomLevel = 3; // Example zoom level, adjust as necessary
      const { offsetX, offsetY, target } = event;

      const originX = (offsetX / target.offsetWidth) * 100;
      const originY = (offsetY / target.offsetHeight) * 100;

      this.$refs.zoomedImage.style.transformOrigin = `${originX}% ${originY}%`;
      this.$refs.zoomedImage.style.transform = `scale(${zoomLevel})`;
    },

    resetZoom() {
      this.$refs.zoomedImage.style.transformOrigin = "center center";
      this.$refs.zoomedImage.style.transform = "scale(1)";
    },
  },
};
</script>

<style lang="scss" scoped>
body,
*,
p,
a,
button {
  font-family: "Kumbh Sans" !important ;
}

.v-btn {
  text-transform: none !important;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #3e3e40;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it's above other content */
}

.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  padding: 20px;
  border-radius: 5px;
}

.hero-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-image: url("~@/assets/bw-bg-v1.png");
  background-repeat: no-repeat;
  // background-position: center center;
  background-size: cover;

  .container {
    display: flex;
    justify-content: center;
    flex-direction: column;

    .nalu-card {
      justify-content: center;
      text-align: center;
      padding: 50px;
    }
  }
}

.hero-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  background: rgba(57, 57, 57, 0.75);
}

.hero-content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.hero-text-container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}

.credit-text {
  display: flex;
  align-items: center; /* This ensures the baseline alignment */
  color: white;
  font-size: 5rem;
  font-weight: 700;
}

.credit-text-tm {
  font-size: 1rem;
  position: relative;
  top: -1.5em; /* Adjust this value as needed to align the TM sign */
}

.hero-subtitle {
  color: white;
  font-size: 1.7rem;
  margin-top: 20px;
}

.hero-btn-wrapper {
  margin-top: 50px;
}

.logo-wrapper {
  display: flex;
}

.application-container {
  background: black;
  border-radius: 10px;
  padding: 50px;
  max-width: 450px;
  width: 100%;
}

.subtext {
  font-size: 1.2rem;
  font-weight: 800;
  color: #9e9e9e;
  text-transform: uppercase;
}

.section {
  position: relative; /* Required for absolute positioning of the child */
  overflow: hidden; /* Optional, ensures the line doesn't extend outside the section */
}

.speed {
  font-size: 20rem;
  color: #d9d9d9;
  font-weight: 900;
  text-transform: uppercase;
}

.lp-container {
  padding: 80px 0 0;
}

.app-vid-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 80px;

  img {
    width: 100%;
    max-width: 550px;
  }

  video {
    width: 100%;
    max-width: 800px;
  }
}

.app-stores-wrapper {
  margin-top: 40px;

  img {
    width: 150px;
  }
}

.header-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.text-1 {
  font-size: 2.5rem;
  font-weight: 800;
  max-width: 600px;
}

.rounded-border {
  border-radius: 8px;
  border: 2px solid lightgrey;
}

.shadow {
  box-shadow: 0 8px 6px 2px #a2a2a2;
}

.image-container {
  position: relative;
  overflow: hidden;
}

.zoom-image {
  transition: transform 0.2s ease-out;
  /* Adjust the size as needed */
  width: 100%;
  height: auto;
}

.zoom-area {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: zoom-in;
}

.c-footer {
  background: black;
  padding: 50px 0;

  i {
    margin: 0 5px;
  }
}

.footer-menu-title {
  color: white;
  font-size: 1.4rem;
  font-weight: 700;
  padding-bottom: 15px;
}

.footer-menu-item {
  color: white;
  padding-top: 15px;
}

.footer-menu-item-2 {
  color: white;
  font-size: 0.8rem;
  align-items: center;
  display: flex;
}

.footer-divider {
  height: 1px; /* Thickness of the divider */
  background-color: white; /* Color of the divider */
  width: 100%; /* Width of the divider */
  margin: 20px 0; /* Center the divider and add some vertical space */
}

@media only screen and (min-width: 769px) {
  /* Styles for screens larger than 768px */

  .header-wrapper {
    margin-top: 100px;
  }

  .logo {
    width: 175px;
  }

  .hero-container,
  .hero-wrapper {
    height: 100vh;
  }

  // .hero-content {
  //   padding-top: 100px;
  // }

  .hero-subtitle {
    max-width: 700px;
  }

  // .hero-text-container {
  //   margin-top: 150px;
  // }

  .btn-right {
    margin-left: 20px;
  }

  .f-privacy-policy {
    justify-content: flex-end;
  }

  .mobile-form {
    display: none !important;
  }

  .lp-2 {
    padding-bottom: 80px;
  }
}

@media only screen and (max-width: 768px) {
  .container {
    padding: 24px;
  }

  .desktop-form {
    display: none !important;
  }

  .mobile-form {
    .application-container {
      border-radius: 0px;
      padding: 48px 24px !important;
    }
  }

  .hero-text-container {
    margin-top: 100px;
  }

  .hero-wrapper {
    padding-bottom: 40px;
  }

  .logo {
    width: 125px;
  }

  .credit-text {
    font-size: 2rem;
  }

  .credit-text-tm {
    font-size: 1rem;
    top: -0.25em; /* Adjust for smaller screen */
  }

  .hero-subtitle {
    font-size: 1.2rem;
  }

  .lp-container {
    padding: 40px 0 0;
  }

  .app-vid-wrapper {
    margin-top: 40px;
  }

  .text-1 {
    font-size: 2rem;
  }

  .btn-right {
    margin-top: 20px;
  }

  .m-no {
    display: none;
  }

  .f-privacy-policy {
    justify-content: flex-start;
  }

  .lp-2 {
    padding-bottom: 40px;
  }
}
</style>
